import React from "react";
import { MultiSelect } from 'primereact/multiselect';

const DoMultiSelectField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const optionLabel = props.optionLabel;
    const name = props.name;
    const options = props.options;
    const placeholder = props.placeholder;
    const maxSelectedLabels = props.maxSelectedLabels;
    let field = props.field;

    return (
        <div className="flex flex-column ">
            <label htmlFor={id} className="text-capitalize">{label}</label>
            <MultiSelect
                id={field.name}
                name={name}
                value={field.value}
                options={options}
                onChange={(e) => field.onChange(e.value)}
                optionLabel={optionLabel}
                placeholder={placeholder}
                maxSelectedLabels={maxSelectedLabels}
            />
            {props.fieldState.invalid ? props.errors[props.name]?.message : ''}
        </div>
    )

}

export default DoMultiSelectField;