import React from "react";
import { Password } from 'primereact/password';

const PasswordField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;   
    let field = props.field;

    return (
        <div className="flex flex-column">
            <label htmlFor={id} className="text-capitalize">{label}</label>
            <Password id={id} name={name}  {...field} inputRef={field.ref}  feedback={false} toggleMask />           
        </div>
    )

}

export default PasswordField;