import React from "react";
import { Calendar } from 'primereact/calendar';

const DoDateField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;    
    const name = props.name;
    const placeholder = props.placeholder;
    const selectionMode = props.selectionMode;
    const showButtonBar = props.showButtons;
    let field = props.field;

    return (
        <div className="flex flex-column ">
            <label htmlFor={id} className="text-capitalize">{label}</label>
            
            <Calendar
                showButtonBar={showButtonBar ? true : false}
                selectionMode={selectionMode ? selectionMode : 'single'}
                inputId={field.name}
                value={field.value}
                onChange={field.onChange}
                dateFormat="dd/mm/yy"
                placeholder={placeholder}
            />

            {props.fieldState.invalid ? props.errors[props.name]?.message : ''}
        </div>
    )

}

export default DoDateField;