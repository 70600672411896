import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import ApproveOrReject from "../../CommonModals/ApproveOrReject";
// config file

const Leaves = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let loginRole = JSON.parse(localStorage.getItem('loginCredentials'))

  const params = useParams();

  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Leaves");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const customFun = (rowData) => {
    let arr = [];

    if (rowData && rowData.status) {
      if (rowData.status === "Pending") {
        arr.push('edit', 'delete');
      } else if (rowData.status === 'Approved') {
        if (loginRole.roleType === 'Admin') {
          arr.push('delete');
        }
      }
    }
    return arr
  }

  const getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employeeName",
        type: "relateAutoComplete",
        placeholder: "Employee Name",
        label: "Employee",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Employee",
        derivedValue: "employeeName=employeeName=name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        isClickable: false,
        id: "employeeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        displayFields: ["FirstName"],
        controllerName: "employee",
        searchApi: "employees",
        textAlign: "Center",
        show: true,
        field: "employeeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Applied Date",
        width: 90,
        header: "Applied Date",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        isClickable: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        fieldName: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        customFun: customFun,
        mobile: true,
        tDisplay: true,
      },
      {
        name: "leaveType",
        type: "dropDown",
        placeholder: "Leave Type",
        label: "Leave Type",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Leave Type",
        derivedValue:
          "leaveType=leaveType=LeaveType=LeaveType=LeaveType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "leaveType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Sick Leave", value: "Sick Leave", color: "primary" },
          { label: "Casual Leave", value: "Casual Leave", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Sick Leave", value: "Sick Leave" },
          { label: "Casual Leave", value: "Casual Leave" },
        ],
        defaultValues: ['Sick Leave', 'Casual Leave'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "leaveType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "leaveDay",
        type: "dropDown",
        placeholder: "Leave Day",
        label: "Leave Day",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Leave Day",
        derivedValue: "leaveDay=leaveDay=LeaveDay=LeaveDay=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "leaveDay",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Half Day", value: "Half Day", color: "warning" },
          { label: "Full Day", value: "Full Day", color: "success" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Half Day", value: "Half Day" },
          { label: "Full Day", value: "Full Day" },
        ],
        defaultValues: ['Half Day', 'Full Day'],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "leaveDay",
        showOrHideFields: [],
        label0: "Half Day",
        color0: "warning",
        value0: "Half Day",
        label1: "Full Day",
        color1: "success",
        value1: "Full Day",
        fieldName: "leaveDay",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reason",
        type: "textarea",
        placeholder: "Reason",
        label: "Reason",
        width: "200px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Reason",
        derivedValue: "reason=reason=Reason=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reason",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        style: { width: '250px', textOverflow: "ellipsis", overflow: "hidden" },
        show: true,
        field: "reason",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Status",
        derivedValue:
          "status=status=status=status=status=status=status=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Approved", value: "Approved", color: "success" },
          { label: "Pending", value: "Pending", color: "danger", },
          { label: "Rejected", value: "Rejected", color: "secondary", },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Approved", value: "Approved" },
          { label: "Pending", value: "Pending", },
          { label: "Rejected", value: "Rejected", },],
        defaultValues: ['Approved', 'Pending', 'Rejected'],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfDays",
        type: "number",
        placeholder: "numberOfDays",
        label: "No Of Days",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "No Of Days",
        derivedValue: "numberOfDays=numberOfDays=noOfDays=NoOfDays=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "numberOfDays",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "numberOfDays",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fromDate",
        type: "date",
        placeholder: "From Date",
        label: "From Date",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "From Date",
        derivedValue: "fromDate=fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "fromDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "toDate",
        type: "date",
        placeholder: "To Date",
        label: "To Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "To Date",
        derivedValue: "toDate=toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "toDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "rejectReason",
        type: "text",
        placeholder: "RejectReason",
        label: "RejectReason",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "RejectReason",
        derivedValue: "rejectReason=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "rejectReason",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "rejectReason",
        showOrHideFields: [],
        fieldName: "rejectReason",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "halfDayType",
        type: "text",
        placeholder: "HalfDay Type",
        label: "HalfDay Type",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "HalfDay Type",
        derivedValue: "halfDayType=halfDayType=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "halfDayType",
        options: [
          { label: "First Half", value: "First half", color: "warning" },
          { label: "Second Half", value: "Second half", color: "success" },
        ],
        fieldType: "dropDown",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "halfDayType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        type: "text",
        placeholder: "Employee Id",
        label: "Employee Id",
        width: "130px",
        addFormOrder: 26,
        editFormOrder: 26,
        header: "Employee Id",
        derivedValue: "companyEmpId=companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        fieldName: "companyEmpId",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reportingTo",
        type: "text",
        placeholder: "Reporting To",
        label: "Reporting To",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Reporting To",
        derivedValue: "reportingToName=reportingToName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reportingTo",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        searchApi: "employees",
        searchField: "displayName",
        textAlign: "Center",
        show: true,
        field: "reportingToName",
        showOrHideFields: [],
        fieldName: "reportingTo",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {

    return [
      {
        name: "employeeName",
        value: loginRole && loginRole.displayName ? loginRole : '',
        type: "relateAutoComplete",
        placeholder: "Employee Name",
        label: "Employee",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "employeeName=employeeName=name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: true,
        id: "employeeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        displayFields: ["FirstName"],
        controllerName: "employee",
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "leaveType",
        type: "dropDown",
        placeholder: "Leave Type",
        label: "Leave Type",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue:
          "leaveType=leaveType=LeaveType=LeaveType=LeaveType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "leaveType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Sick Leave", value: "Sick Leave", color: "primary" },
          { label: "Casual Leave", value: "Casual Leave", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Sick Leave", value: "Sick Leave", color: "primary" },
          { label: "Casual Leave", value: "Casual Leave", color: "primary" },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "leaveDay",
        type: "dropDown",
        placeholder: "Leave Day",
        label: "Leave Day",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "leaveDay=leaveDay=LeaveDay=LeaveDay=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "leaveDay",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Half Day", value: "Half Day", color: "warning" },
          { label: "Full Day", value: "Full Day", color: "success" },
        ],
        fieldType: "dropDown",
        show: true,
        showOrHideFields: [],
        label0: "Half Day",
        color0: "warning",
        value0: "Half Day",
        label1: "Full Day",
        color1: "success",
        value1: "Full Day",
        fieldName: "leaveDay",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: [{ "Half Day": ["halfDayType"] }],
      },
      {
        name: "reason",
        type: "textarea",
        placeholder: "Reason",
        label: "Reason",
        width: "200px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "reason=reason=Reason=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "reason",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "fromDate",
        type: "date",
        placeholder: "From Date",
        label: "From Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "fromDate=fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "toDate",
        type: "date",
        placeholder: "To Date",
        label: "To Date",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "toDate=toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "halfDayType",
        type: "dropDown",
        placeholder: "HalfDay Type",
        label: "HalfDay Type",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "halfDayType=halfDayType=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "halfDayType",
        options: [
          { label: "First Half", value: "First half", color: "warning" },
          { label: "Second Half", value: "Second half", color: "success" },
        ],
        fieldType: "dropDown",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
  };

  const getMobileTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employeeName",
        type: "relateAutoComplete",
        placeholder: "Employee Name",
        label: "Employee",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Employee",
        derivedValue: "employeeName=employeeName=name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        isClickable: true,
        id: "employeeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "FirstName",
        fieldType: "relateAutoComplete",
        displayFields: ["FirstName"],
        controllerName: "employee",
        searchApi: "employees",
        textAlign: "Center",
        show: true,
        field: "employeeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Applied Date",
        width: 90,
        header: "Applied Date",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        fieldName: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "leaveType",
        type: "dropDown",
        placeholder: "Leave Type",
        label: "Leave Type",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Leave Type",
        derivedValue:
          "leaveType=leaveType=LeaveType=LeaveType=LeaveType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "leaveType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Sick Leave", value: "Sick Leave", color: "primary" },
          { label: "Casual Leave", value: "Casual Leave", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Sick Leave", value: "Sick Leave", color: "primary" },
          { label: "Casual Leave", value: "Casual Leave", color: "primary" },
        ],
        defaultValues: ['Sick Leave', 'Casual Leave'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "leaveType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "leaveDay",
        type: "dropDown",
        placeholder: "Leave Day",
        label: "Leave Day",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Leave Day",
        derivedValue: "leaveDay=leaveDay=LeaveDay=LeaveDay=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "leaveDay",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Half Day", value: "Half Day", color: "warning" },
          { label: "Full Day", value: "Full Day", color: "success" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Half Day", value: "Half Day" },
          { label: "Full Day", value: "Full Day" },
        ],
        defaultValues: ['Half Day', 'Full Day'],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "leaveDay",
        showOrHideFields: [],
        label0: "Half Day",
        color0: "warning",
        value0: "Half Day",
        label1: "Full Day",
        color1: "success",
        value1: "Full Day",
        fieldName: "leaveDay",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reason",
        type: "textarea",
        placeholder: "Reason",
        label: "Reason",
        width: "200px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Reason",
        derivedValue: "reason=reason=Reason=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reason",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "reason",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Status",
        derivedValue:
          "status=status=status=status=status=status=status=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          {
            label: "Approved",
            value: "Approved",
            color: "success",
            dependentField: [],
          },
          {
            label: "Pending",
            value: "Pending",
            color: "danger",
            dependentField: [],
          },
          {
            label: "Rejected",
            value: "Rejected",
            color: "warning",
            dependentField: [],
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Approved", value: "Approved" },
          { label: "Pending", value: "Pending", },
          { label: "Rejected", value: "Rejected", },],
        defaultValues: ['Approved', 'Pending', 'Rejected'],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfDays",
        type: "number",
        placeholder: "numberOfDays",
        label: "No Of Days",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "No Of Days",
        derivedValue: "numberOfDays=numberOfDays=noOfDays=NoOfDays=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "numberOfDays",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "numberOfDays",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fromDate",
        type: "date",
        placeholder: "From Date",
        label: "From Date",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "From Date",
        derivedValue: "fromDate=fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "fromDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "toDate",
        type: "date",
        placeholder: "To Date",
        label: "To Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "To Date",
        derivedValue: "toDate=toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "toDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "rejectReason",
        type: "text",
        placeholder: "RejectReason",
        label: "RejectReason",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "RejectReason",
        derivedValue: "rejectReason=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "rejectReason",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "rejectReason",
        showOrHideFields: [],
        fieldName: "rejectReason",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "halfDayType",
        type: "text",
        placeholder: "HalfDay Type",
        label: "HalfDay Type",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "HalfDay Type",
        derivedValue: "halfDayType=halfDayType=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "halfDayType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "halfDayType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        type: "text",
        placeholder: "Employee Id",
        label: "Employee Id",
        width: "130px",
        addFormOrder: 26,
        editFormOrder: 26,
        header: "Employee Id",
        derivedValue: "companyEmpId=companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        fieldName: "companyEmpId",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "reportingToName",
        type: "text",
        placeholder: "Reporting To",
        label: "Reporting To",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Reporting To",
        derivedValue: "reportingToName=reportingToName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "reportingToName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "reportingToName",
        showOrHideFields: [],
        fieldName: "reportingToName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

  };

  const saveDataToServer = async (item, field, value, getDataFromServer) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.leaves}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.response.respCode) {
            getDataFromServer();
            showToasterMessage(response.response.respMessage, "success");
          } else if (response && response.response.errorMessage) {
            showToasterMessage(response.response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  //Displaying custom action icons approve and reject based on conditions
  const customActionButton = (item, getData, filters) => {

    return (
      <>
        {item.status == 'Pending' && (loginRole.roleType === 'Admin' || item.approved === true) ?
          <ApproveOrReject
            item={item}
            getDataFromServer={getData}
            filterCriteria={filters}
            type={'leaves'}
          />
          : null}
      </>
    )
  }

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          printRequried={true}
          actionsTypes={[
            {
              name: "Delete",
              options: [
                {
                  label: "Delete",
                  value: "Delete",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },
            {
              name: "Approved",
              action: saveDataToServer,
              options: [
                {
                  label: "Approve",
                  field: "status",
                  value: "Approved",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: false,
                },
              ],
            },
            {
              name: "Rejected",
              action: saveDataToServer,
              options: [
                {
                  label: "Reject",
                  field: "status",
                  value: "Rejected",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: false,
                },
              ],
            },
            {
              name: "Pending",
              action: saveDataToServer,
              options: [
                {
                  label: "Pending",
                  field: "status",
                  value: "Pending",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: false,
                },
              ],
            },
          ]}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.leaves}
          globalSearch={
            "leaveType/leaveDay/reason/status/reportingTo/rejectReason/role/email/halfDayType/companyEmpId/reportingToName/Type"
          }
          displayName="Leaves"
          type="Leaves"
          routeTo={apiCalls.leaves}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.leaves.toLowerCase()}
          apiUrl={apiCalls.leaves}
          selectedId={params.id}
          customActionButton={customActionButton}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="leaves"
          apiUrl={apiCalls.leaves}
        />
      ) : null}
    </span>
  );
};

export default Leaves;
