/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';

import Profile from './components/Profile';
import fetch from '../../../config/service';

import apiCalls from '../../../config/apiCalls';

class ProfilePage extends PureComponent {
  constructor(props) {
    super(props);
  }


  // submit form data

 

  render() {
    return (
      <Container>
        <Row>
          <Profile
            onRef={(ref) => { this.profileRef = ref }}            
            locationProps={this.props}
          />
        </Row>
      </Container>
    );
  }
}

export default ProfilePage;
