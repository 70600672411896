/* eslint indent: "off" */
import React from 'react';
import { Route, Routes, createBrowserRouter,Outlet } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Register from '../Register/index';

// Login
import Landing from '../Landing/LogIn';
// import LoginCheck from '../Landing/loginCheck';

// Dashboard
import Home from '../Dashboard/index';

// Profile
import ChangePassword from '../Account/ChangePassword/index';
import ChangeRecoverPassword from '../Account/ChangeRecoverPassword/index';
import ForgotPassword from '../Account/ForgotPassword/index';
import LoginChangePassword from '../Account/LoginChangePassword/index';
import Profile from '../Account/Profile';

import EmailTemplate from '../Cruds/EmailTemplates';
import Roles from '../Cruds/Roles';
import Activities from '../Cruds/Activities'
import EmailStatus from '../Cruds/EmailStatus/index';
// Tables
import Employees from '../Cruds/Employees/index';
import Projects from '../Cruds/Projects/index';
import Tasks from '../Cruds/Tasks/index';
import TimeSheets from '../Cruds/TimeSheets/index';
import Leaves from '../Cruds/Leaves/index';
import Issues from '../Cruds/Issues/index';
import DailyStatuses from '../Cruds/DailyStatuses/index';
import Dashboards from '../Cruds/Dashboards/index';
import Attendences from '../Cruds/Attendences/index';
import LeaveHistories from '../Cruds/LeaveHistories/index';
import PublicHolidays from '../Cruds/PublicHolidays/index';
import Inventories from '../Cruds/Inventories/index';
import InventoryHistories from '../Cruds/InventoryHistories/index';
import Appraisals from '../Cruds/Appraisals/index';
import Presentations from '../Cruds/Presentations/index';
import Clients from '../Cruds/Clients/index';
import Setups from '../Cruds/Setups/index';
import Sprints from '../Cruds/Sprints/index';

// import Employees from '../Cruds/Employees/index';

import Settings from '../Cruds/Settings/index'
import AdminSettings from '../Settings/index'
import Uploads from '../Cruds/Uploads/index';
// 404 page not found 
import ErrorNotFound from '../Account/404/index';
import config from '../../config/config';
import SampleForm from '../../Sample/SampleForm.jsx';



const Tables = () => {
  let roles = localStorage.rolePermissions
    ? JSON.parse(localStorage.rolePermissions)
    : false;
  if (roles) {
    return (
      <>
    <Routes>
      <Route path='/sampleform'
        element={<SampleForm />}
      ></Route>
<Route exact
       path="/employees"
      element={
              roles['Employees'] &&
                (roles['Employees'] === "Edit" || roles['Employees'] === "View")
                ? <Employees/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_employees"
          element={
            roles['Employees'] &&
              (roles['Employees'] === "Edit" )
              ? <Employees/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_employees/:id"
          element={
            roles['Employees'] &&
              (roles['Employees'] === "Edit" )
              ? <Employees/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_employees/:id"
          element={
            roles['Employees'] &&
              (roles['Employees'] === "Edit" || roles['Employees'] === "View")
              ? <Employees  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/projects"
      element={
              roles['Projects'] &&
                (roles['Projects'] === "Edit" || roles['Projects'] === "View")
                ? <Projects/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_projects"
          element={
            roles['Projects'] &&
              (roles['Projects'] === "Edit" )
              ? <Projects/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_projects/:id"
          element={
            roles['Projects'] &&
              (roles['Projects'] === "Edit" )
              ? <Projects/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_projects/:id"
          element={
            roles['Projects'] &&
              (roles['Projects'] === "Edit" || roles['Projects'] === "View")
              ? <Projects  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/tasks"
      element={
              roles['Tasks'] &&
                (roles['Tasks'] === "Edit" || roles['Tasks'] === "View")
                ? <Tasks/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_tasks"
          element={
            roles['Tasks'] &&
              (roles['Tasks'] === "Edit" )
              ? <Tasks/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_tasks/:id"
          element={
            roles['Tasks'] &&
              (roles['Tasks'] === "Edit" )
              ? <Tasks/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_tasks/:id"
          element={
            roles['Tasks'] &&
              (roles['Tasks'] === "Edit" || roles['Tasks'] === "View")
              ? <Tasks  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/timeSheets"
      element={
              roles['TimeSheets'] &&
                (roles['TimeSheets'] === "Edit" || roles['TimeSheets'] === "View")
                ? <TimeSheets/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_timeSheets"
          element={
            roles['TimeSheets'] &&
              (roles['TimeSheets'] === "Edit" )
              ? <TimeSheets/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_timeSheets/:id"
          element={
            roles['TimeSheets'] &&
              (roles['TimeSheets'] === "Edit" )
              ? <TimeSheets/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_timeSheets/:id"
          element={
            roles['TimeSheets'] &&
              (roles['TimeSheets'] === "Edit" || roles['TimeSheets'] === "View")
              ? <TimeSheets  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/leaves"
      element={
              roles['Leaves'] &&
                (roles['Leaves'] === "Edit" || roles['Leaves'] === "View")
                ? <Leaves/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_leaves"
          element={
            roles['Leaves'] &&
              (roles['Leaves'] === "Edit" )
              ? <Leaves/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_leaves/:id"
          element={
            roles['Leaves'] &&
              (roles['Leaves'] === "Edit" )
              ? <Leaves/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_leaves/:id"
          element={
            roles['Leaves'] &&
              (roles['Leaves'] === "Edit" || roles['Leaves'] === "View")
              ? <Leaves  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/issues"
      element={
              roles['Issues'] &&
                (roles['Issues'] === "Edit" || roles['Issues'] === "View")
                ? <Issues/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_issues"
          element={
            roles['Issues'] &&
              (roles['Issues'] === "Edit" )
              ? <Issues/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_issues/:id"
          element={
            roles['Issues'] &&
              (roles['Issues'] === "Edit" )
              ? <Issues/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_issues/:id"
          element={
            roles['Issues'] &&
              (roles['Issues'] === "Edit" || roles['Issues'] === "View")
              ? <Issues  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/dailyStatuses"
      element={
              roles['DailyStatuses'] &&
                (roles['DailyStatuses'] === "Edit" || roles['DailyStatuses'] === "View")
                ? <DailyStatuses/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_dailyStatuses"
          element={
            roles['DailyStatuses'] &&
              (roles['DailyStatuses'] === "Edit" )
              ? <DailyStatuses/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_dailyStatuses/:id"
          element={
            roles['DailyStatuses'] &&
              (roles['DailyStatuses'] === "Edit" )
              ? <DailyStatuses/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_dailyStatuses/:id"
          element={
            roles['DailyStatuses'] &&
              (roles['DailyStatuses'] === "Edit" || roles['DailyStatuses'] === "View")
              ? <DailyStatuses  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/dashboards"
      element={
              roles['Dashboards'] &&
                (roles['Dashboards'] === "Edit" || roles['Dashboards'] === "View")
                ? <Dashboards/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_dashboards"
          element={
            roles['Dashboards'] &&
              (roles['Dashboards'] === "Edit" )
              ? <Dashboards/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_dashboards/:id"
          element={
            roles['Dashboards'] &&
              (roles['Dashboards'] === "Edit" )
              ? <Dashboards/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_dashboards/:id"
          element={
            roles['Dashboards'] &&
              (roles['Dashboards'] === "Edit" || roles['Dashboards'] === "View")
              ? <Dashboards  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/attendences"
      element={
              roles['Attendences'] &&
                (roles['Attendences'] === "Edit" || roles['Attendences'] === "View")
                ? <Attendences/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_attendences"
          element={
            roles['Attendences'] &&
              (roles['Attendences'] === "Edit" )
              ? <Attendences/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_attendences/:id"
          element={
            roles['Attendences'] &&
              (roles['Attendences'] === "Edit" )
              ? <Attendences/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_attendences/:id"
          element={
            roles['Attendences'] &&
              (roles['Attendences'] === "Edit" || roles['Attendences'] === "View")
              ? <Attendences  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/leaveHistories"
      element={
              roles['Leave Histories'] &&
                (roles['Leave Histories'] === "Edit" || roles['Leave Histories'] === "View")
                ? <LeaveHistories/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_leaveHistories"
          element={
            roles['Leave Histories'] &&
              (roles['Leave Histories'] === "Edit" )
              ? <LeaveHistories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_leaveHistories/:id"
          element={
            roles['Leave Histories'] &&
              (roles['Leave Histories'] === "Edit" )
              ? <LeaveHistories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_leaveHistories/:id"
          element={
            roles['Leave Histories'] &&
              (roles['Leave Histories'] === "Edit" || roles['Leave Histories'] === "View")
              ? <LeaveHistories  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/publicHolidays"
      element={
              roles['Public Holidays'] &&
                (roles['Public Holidays'] === "Edit" || roles['Public Holidays'] === "View")
                ? <PublicHolidays/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_publicHolidays"
          element={
            roles['Public Holidays'] &&
              (roles['Public Holidays'] === "Edit" )
              ? <PublicHolidays/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_publicHolidays/:id"
          element={
            roles['Public Holidays'] &&
              (roles['Public Holidays'] === "Edit" )
              ? <PublicHolidays/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_publicHolidays/:id"
          element={
            roles['Public Holidays'] &&
              (roles['Public Holidays'] === "Edit" || roles['Public Holidays'] === "View")
              ? <PublicHolidays  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/inventories"
      element={
              roles['Inventories'] &&
                (roles['Inventories'] === "Edit" || roles['Inventories'] === "View")
                ? <Inventories/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_inventories"
          element={
            roles['Inventories'] &&
              (roles['Inventories'] === "Edit" )
              ? <Inventories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_inventories/:id"
          element={
            roles['Inventories'] &&
              (roles['Inventories'] === "Edit" )
              ? <Inventories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_inventories/:id"
          element={
            roles['Inventories'] &&
              (roles['Inventories'] === "Edit" || roles['Inventories'] === "View")
              ? <Inventories  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/inventoryHistories"
      element={
              roles['Inventory Histories'] &&
                (roles['Inventory Histories'] === "Edit" || roles['Inventory Histories'] === "View")
                ? <InventoryHistories/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_inventoryHistories"
          element={
            roles['Inventory Histories'] &&
              (roles['Inventory Histories'] === "Edit" )
              ? <InventoryHistories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_inventoryHistories/:id"
          element={
            roles['Inventory Histories'] &&
              (roles['Inventory Histories'] === "Edit" )
              ? <InventoryHistories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_inventoryHistories/:id"
          element={
            roles['Inventory Histories'] &&
              (roles['Inventory Histories'] === "Edit" || roles['Inventory Histories'] === "View")
              ? <InventoryHistories  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/appraisals"
      element={
              roles['Appraisals'] &&
                (roles['Appraisals'] === "Edit" || roles['Appraisals'] === "View")
                ? <Appraisals/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_appraisals"
          element={
            roles['Appraisals'] &&
              (roles['Appraisals'] === "Edit" )
              ? <Appraisals/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_appraisals/:id"
          element={
            roles['Appraisals'] &&
              (roles['Appraisals'] === "Edit" )
              ? <Appraisals/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_appraisals/:id"
          element={
            roles['Appraisals'] &&
              (roles['Appraisals'] === "Edit" || roles['Appraisals'] === "View")
              ? <Appraisals  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/presentations"
      element={
              roles['Presentations'] &&
                (roles['Presentations'] === "Edit" || roles['Presentations'] === "View")
                ? <Presentations/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_presentations"
          element={
            roles['Presentations'] &&
              (roles['Presentations'] === "Edit" )
              ? <Presentations/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_presentations/:id"
          element={
            roles['Presentations'] &&
              (roles['Presentations'] === "Edit" )
              ? <Presentations/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_presentations/:id"
          element={
            roles['Presentations'] &&
              (roles['Presentations'] === "Edit" || roles['Presentations'] === "View")
              ? <Presentations  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/clients"
      element={
              roles['Clients'] &&
                (roles['Clients'] === "Edit" || roles['Clients'] === "View")
                ? <Clients/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_clients"
          element={
            roles['Clients'] &&
              (roles['Clients'] === "Edit" )
              ? <Clients/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_clients/:id"
          element={
            roles['Clients'] &&
              (roles['Clients'] === "Edit" )
              ? <Clients/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_clients/:id"
          element={
            roles['Clients'] &&
              (roles['Clients'] === "Edit" || roles['Clients'] === "View")
              ? <Clients  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/setups"
      element={
              roles['Setups'] &&
                (roles['Setups'] === "Edit" || roles['Setups'] === "View")
                ? <Setups/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_setups"
          element={
            roles['Setups'] &&
              (roles['Setups'] === "Edit" )
              ? <Setups/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_setups/:id"
          element={
            roles['Setups'] &&
              (roles['Setups'] === "Edit" )
              ? <Setups/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_setups/:id"
          element={
            roles['Setups'] &&
              (roles['Setups'] === "Edit" || roles['Setups'] === "View")
              ? <Setups  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/sprints"
      element={
              roles['Sprints'] &&
                (roles['Sprints'] === "Edit" || roles['Sprints'] === "View")
                ? <Sprints/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_sprints"
          element={
            roles['Sprints'] &&
              (roles['Sprints'] === "Edit" )
              ? <Sprints/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_sprints/:id"
          element={
            roles['Sprints'] &&
              (roles['Sprints'] === "Edit" )
              ? <Sprints/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_sprints/:id"
          element={
            roles['Sprints'] &&
              (roles['Sprints'] === "Edit" || roles['Sprints'] === "View")
              ? <Sprints  />  
              : <ErrorNotFound/>
          }
        />

      {/* <Route exact
        id="employee"
        path="/employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees />
            : <ErrorNotFound />
        } />

      <Route
        id="create-employee"
        path="/create_employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees/>
            : <ErrorNotFound />
        }
      />

      <Route
        id="edit-employee"
        path="/edit_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees  />
            : <ErrorNotFound />
        }
      />

      <Route
        key="view-employee"
        path="/view_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees  />
            : <ErrorNotFound />
        }
      /> */}

      {/* <Route path="/settings" element={Settings} /> */}
      {/* <Route path="/adminSettings" element={AdminSettings} /> */}
      <Route
        path="/adminSettings"
        element={
          config.displaySettings
            ? <AdminSettings />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/uploads"
        element={
          roles["Upload Histories"] &&
            (roles["Upload Histories"] === "Edit" || roles["Upload Histories"] === "View")
            ? <Uploads />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/activities"
        element={
          roles["Activities"] &&
            (roles["Activities"] === "Edit" || roles["Activities"] === "View")
            ? <Activities />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/roles"
        element={
          roles["Roles"] &&
            (roles["Roles"] === "Edit" || roles["Roles"] === "View")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/create_roles"
        element={
          roles["Roles"] &&
            (roles["Roles"] === "Edit")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/edit_roles/:id"
        element={
          roles["Roles"] &&
            (roles["Roles"] === "Edit")
            ? <Roles  />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/view_roles/:id"
        element={
          roles["Roles"] &&
            (roles["Roles"] === "Edit" || roles["Roles"] === "View")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/emailStatuses"
        element={
          roles["Email Statuses"] &&
            (roles["Email Statuses"] === "Edit" || roles["Email Statuses"] === "View")
            ? <EmailStatus />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/templates"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"] === "Edit" || roles["Email Templates"] === "View")
            ? <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/create_templates"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"] === "Edit")
            ?<EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/edit_templates/:id"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"] === "Edit")
            ?  <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/view_templates/:id"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"] === "Edit" || roles["Email Templates"] === "View")
            ? <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      {/* <Route path="/uploads" element={Uploads} />
    <Route path="/activities" element={Activities} />
    <Route path="/roles" element={Roles} />
    <Route path="/templates" element={EmailTemplate} /> */}
    </Routes >
    <Outlet />
    </>
    )
  }
}

const Account = () => (
  <Routes>
    <Route path="/changepassword" element={<ChangePassword />} />
    <Route path="/profile" element={<Profile />} />
    
  </Routes>
  
);

const WrappedRoutes = () => {
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  if (loginCredentials) {
    return <div>
      <h1>Hello</h1>
      <Layout />
      <div className="container__wrap">
        <Routes>
          {/* <Route path="/" element={<Account />} /> */}
          <Route path="/*" index={true} element={<Tables />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashBoard" element={<Home />} />
        </Routes>

      </div>
      <Outlet />
    </div>
  }
  else {
    return <ErrorNotFound />
  }
}

const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/changeRecoverPassword/:enEmail" element={<ChangeRecoverPassword />} />
        <Route path="/loginChangePassword" element={<LoginChangePassword />} />
        <Route exact path="/" element={<Landing />} />
        <Route path="/log_in" element={<Landing />} />
        <Route path="/logout" element={<Landing />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />

        <Route path="/*" element={<WrappedRoutes />}  />
        
        <Route element={<ErrorNotFound />} />


      </Routes>
      <Outlet />
    </main>
  </MainWrapper>
);

export default Router;
