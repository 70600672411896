import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import ApproveOrReject from "../../CommonModals/ApproveOrReject";
// config file

const Tasks = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [loginRole, setLoginRole] = useState([]);

  const params = useParams();

  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Tasks");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    let login = JSON.parse(localStorage.getItem('loginCredentials'))
    setLoginRole(login.role);
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        fieldName: "createdByName",
        controllerName: "employee",
        searchApi: "employees",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectName",
        type: "relateAutoComplete",
        placeholder: "Projectname",
        label: "Project",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Project",
        derivedValue:
          "projectId=projects=Projects=Projects=Projects=Projects=Projects=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "projectName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Project",
        hasDependency: "false",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["ProjectName"],
        controllerName: "Project",
        searchApi: "projects",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "projectId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskSummary",
        type: "text",
        placeholder: "taskSummary",
        label: "taskSummary",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "taskSummary",
        derivedValue:
          "taskSummary=TaskSummary=TaskSummary=TaskSummary=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskSummary",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: { width: '300px', textOverflow: "ellipsis", overflow: "hidden" },
        tDisplay: true,
      },
      {
        name: "priority",
        type: "dropDown",
        placeholder: "priority",
        label: "priority",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "priority",
        derivedValue: "priority=priority=Priority=Priority=Priority=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "priority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Blocker", value: "Blocker", color: "primary" },
          { label: "Major", value: "Major", color: "primary" },
          { label: "Minor", value: "Minor", color: "primary" },
          { label: "Critical", value: "Critical", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Blocker", value: "blocker", color: "primary" },
          { label: "Major", value: "major", color: "primary" },
          { label: "Minor", value: "minor", color: "primary" },
          { label: "Critical", value: "critical", color: "primary" },
        ],
        defaultValues: ['critical', 'major', 'minor', 'blocker'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "priority",
        showOrHideFields: [],
        fieldName: "priority",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        type: "dropDown",
        placeholder: "type",
        value: "new",
        label: "type",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "type",
        derivedValue: "type=type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "New", value: "New", color: "primary" },
          { label: "Defect", value: "Defect", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "New", value: "new", color: "primary" },
          { label: "Defect", value: "defect", color: "secondary" },
        ],
        defaultValues: ['new', 'defect'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedTo",
        type: "dropDown",
        placeholder: "Assigned To",
        label: "Assigned To",
        width: "150px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Assigned To",
        derivedValue: "AssignedTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        filter: true,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "assignedTo",
        showOrHideFields: [],
        fieldName: "assignedTo",
        mobile: true,
        displayInSettings: true,
      },
      // {
      //   name: "status",
      //   type: "dropDown",
      //   placeholder: "Status",
      //   label: "Status",
      //   width: "130px",
      //   addFormOrder: 11,
      //   editFormOrder: 11,
      //   header: "Status",
      //   derivedValue:
      //     "status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
      //   capitalizeTableText: true,
      //   sortable: true,
      //   filter: true,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "status",
      //   displayinaddForm: "false",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: true,
      //   globalSearchField: "true",
      //   controllerId: null,
      //   options: [
      //     { label: "Pending", value: "Pending", color: "warning" },
      //     { label: "Active", value: "Active", color: "success" },
      //     { label: "Inactive", value: "Inactive", color: "info" },
      //     { label: "Completed", value: "Completed", color: "dark" },
      //   ],
      //   fieldType: "dropDown",
      //   filterElement: [
      //     { label: "Pending", value: "Pending", color: "warning" },
      //     { label: "Active", value: "Active", color: "success" },
      //     { label: "Inactive", value: "Inactive", color: "info" },
      //     { label: "Completed", value: "Completed", color: "dark" },
      //   ],
      //   defaultValues: ['Pending', 'Active'],
      //   dependent: [],
      //   textAlign: "Center",
      //   show: true,
      //   field: "status",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "taskID",
        type: "uniqueID",
        placeholder: "TaskID",
        label: "Task ID",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Task ID",
        derivedValue: "taskID=task_id=task_id=task_id=task_id=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskID",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "6",
        textAlign: "Center",
        show: true,
        field: "taskID",
        showOrHideFields: [],
        fieldName: "taskID",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskStatus",
        type: "dropDown",
        placeholder: "TaskStatus",
        value: "New",
        label: "TaskStatus",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "TaskStatus",
        derivedValue:
          "taskStatus=taskStatus=taskStatus=taskStatus=task_status=task_status=task_status=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "New", value: "new", color: "primary" },
          { label: "In Development", value: "in development", color: "secondary" },
          { label: "Development Completed", value: "development completed", color: "success" },
          { label: "Analysis/Design", value: "analysis/design", color: "primary" },
          { label: "Review Completed", value: "review completed", color: "light" },
          { label: "Ready For Testing", value: "ready for testing", color: "warning", },
          { label: "Need Clarification", value: "need clarification", color: "dark" },
          { label: "Reopen", value: "reopen", color: "danger" },
          { label: "Resolved", value: "resolved", color: "info" },
          { label: "Moved to Prod", value: "moved to prod", color: "primary" },
          { label: "On Hold", value: "onhold", color: "primary" },
          { label: "Future Implementation", value: "future implementation", color: "primary" },
          { label: "Unable to Replicate", value: "unable to replicate", color: "primary" },
          { label: "Duplicate", value: "duplicate", color: "primary" },
          { label: "Not an Issue", value: "not an issue", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "New", value: "new", color: "primary" },
          { label: "In Development", value: "in development", color: "secondary" },
          { label: "Development Completed", value: "development completed", color: "success" },
          { label: "Analysis/Design", value: "analysis/design", color: "primary" },
          { label: "Review Completed", value: "review completed", color: "light" },
          { label: "Ready For Testing", value: "ready for testing", color: "warning", },
          { label: "Need Clarification", value: "need clarification", color: "dark" },
          { label: "Reopen", value: "reopen", color: "danger" },
          { label: "Resolved", value: "resolved", color: "info" },
          { label: "Moved to Prod", value: "moved to prod", color: "primary" },
          { label: "On Hold", value: "onhold", color: "primary" },
          { label: "Future Implementation", value: "future implementation", color: "primary" },
          { label: "Unable to Replicate", value: "unable to replicate", color: "primary" },
          { label: "Duplicate", value: "duplicate", color: "primary" },
          { label: "Not an Issue", value: "not an issue", color: "primary" },
        ],
        defaultValues: ['new', 'in development', 'development completed', 'ready for testing', 'analysis/design', 'resolved', 'reopen', 'onhold', 'future implementation', 'moved to prod', 'duplicate', 'unable to replicate', 'review completed', 'need clarification', 'not an issue'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "taskStatus",
        showOrHideFields: [],
        fieldName: "taskStatus",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "screen",
        type: "text",
        placeholder: "Screen",
        label: "Screen",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 4,
        header: "Screen Name",
        derivedValue: "screen=undefined",
        capitalizeTableText: true,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "screen",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "screen",
        showOrHideFields: [],
        fieldName: "screen",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "startDate",
        type: "date",
        placeholder: "startDate",
        label: "startDate",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "startDate",
        derivedValue: "startDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "startDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "endDate",
        type: "date",
        placeholder: "endDate",
        label: "endDate",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "endDate",
        derivedValue: "endDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "endDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "endDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "estimatedHours",
        type: "number",
        placeholder: "estimatedHours",
        label: "estimatedHours",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "estimatedHours",
        derivedValue: "estimatedHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "estimatedHours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "estimatedHours",
        showOrHideFields: [],
        fieldName: "estimatedHours",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "image",
        type: "profile",
        placeholder: "File",
        label: "Attach File",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Attach File",
        derivedValue: "file=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        imagePath: "Task",
        textAlign: "Center",
        show: true,
        field: "image",
        showOrHideFields: [],
        fieldType: "image",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "actualWorkingHours",
        type: "number",
        placeholder: "WorkingHours",
        label: "Worked Hours",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Worked Hours",
        derivedValue: "actualWorkingHours=workedHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "actualWorkingHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "actualWorkingHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        style: { width: '350px', textOverflow: "ellipsis", overflow: "hidden" },
        show: true,
        field: "description",
        showOrHideFields: [],
        fieldName: "description",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sprint",
        type: "relateAutoComplete",
        placeholder: "Sprint Name",
        label: "Sprint Name",
        width: "150px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "Sprint Name",
        derivedValue: "sprint=sprint=sprint=sprintName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "sprint",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "sprint",
        hasDependency: "false",
        searchField: "name",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "sprint",
        searchApi: "sprints",
        isMultiple: true,
        textAlign: "Center",
        show: true,
        field: "sprint",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {

    return [
      {
        name: "projectId",
        type: "relateAutoComplete",
        placeholder: "Project name",
        label: "Project",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 2,
        derivedValue:
          "projectId=projects=Projects=Projects=Projects=Projects=Projects=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "projectId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Project",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["ProjectName"],
        controllerName: "Project",
        searchApi: "projects",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: { key: 'showsTaskId', value: 'Yes', field: 'taskID' }
      },
      {
        name: "name",
        type: "text",
        placeholder: "taskSummary",
        label: "Task Summary",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue:
          "taskSummary=TaskSummary=TaskSummary=TaskSummary=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "priority",
        type: "dropDown",
        placeholder: "priority",
        label: "priority",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "priority=priority=Priority=Priority=Priority=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "priority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Blocker", value: "blocker", color: "primary" },
          { label: "Major", value: "major", color: "primary" },
          { label: "Minor", value: "minor", color: "primary" },
          { label: "Critical", value: "critical", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Blocker", value: "Blocker", color: "primary" },
          { label: "Major", value: "Major", color: "primary" },
          { label: "Minor", value: "Minor", color: "primary" },
          { label: "Critical", value: "Critical", color: "primary" },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        fieldName: "priority",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "type",
        type: "dropDown",
        placeholder: "type",
        value: "new",
        label: "type",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 13,
        derivedValue: "type=type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "New", value: "new", color: "primary" },
          { label: "Defect", value: "defect", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        fieldName: "type",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "assignedTo",
        type: "dropDown",
        placeholder: "Assigned To",
        label: "Assigned To",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 12,
        derivedValue: "assignedTo=assignedTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        // hasDependency: "true",
        // fieldForKey: "employee",
        // hasDependencyField: "employee",
        displayOptionsInActions: false,
        globalSearchField: "false",
        // controllerId: "employee",
        // searchField: "displayName",
        fieldType: "dropDown",
        // populteFields: [],
        // displayFields: ["name"],
        // controllerName: "employee",
        // searchApi: "employees",
        // filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      // {
      //   name: "status",
      //   type: "dropDown",
      //   placeholder: "Status",
      //   label: "Status",
      //   width: "130px",
      //   addFormOrder: 11,
      //   editFormOrder: 11,
      //   derivedValue:
      //     "status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
      //   capitalizeTableText: true,
      //   sortable: true,
      //   filter: true,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "status",
      //   displayinaddForm: "false",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: true,
      //   globalSearchField: "true",
      //   controllerId: null,
      //   options: [
      //     { label: "Pending", value: "Pending", color: "warning" },
      //     { label: "Active", value: "Active", color: "success" },
      //     { label: "Inactive", value: "Inactive", color: "info" },
      //     { label: "Completed", value: "Completed", color: "dark" },
      //   ],
      //   fieldType: "dropDown",
      //   filterElement: [
      //     { label: "Pending", value: "Pending", color: "warning" },
      //     { label: "Active", value: "Active", color: "success" },
      //     { label: "Inactive", value: "Inactive", color: "info" },
      //     { label: "Completed", value: "Completed", color: "dark" },
      //   ],
      //   dependent: [],
      //   show: true,
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   isAddFormHidden: true,
      //   isEditFormHidden: true,
      // },
      {
        name: "taskID",
        type: "uniqueID",
        placeholder: "TaskID",
        label: "TaskID",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 1,
        derivedValue: "taskID=task_id=task_id=task_id=task_id=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "taskID",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "6",
        show: true,
        showOrHideFields: [],
        fieldName: "taskID",
        mobile: true,
        displayInSettings: true,
        isAddFieldDisable: false,
        isEditFieldDisable: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "taskStatus",
        type: "dropDown",
        placeholder: "TaskStatus",
        value: "New",
        label: "TaskStatus",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue:
          "taskStatus=taskStatus=taskStatus=taskStatus=task_status=task_status=task_status=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "New", value: "new", color: "primary" },
          { label: "In Development", value: "in development", color: "secondary" },
          { label: "Development Completed", value: "development completed", color: "success" },
          { label: "Analysis/Design", value: "analysis/design", color: "primary" },
          { label: "Review Completed", value: "review completed", color: "light" },
          { label: "Ready For Testing", value: "ready for testing", color: "warning", },
          { label: "Need Clarification", value: "need clarification", color: "dark" },
          { label: "Reopen", value: "reopen", color: "danger" },
          { label: "Resolved", value: "resolved", color: "info" },
          { label: "Moved to Prod", value: "moved to prod", color: "primary" },
          { label: "On Hold", value: "onhold", color: "primary" },
          { label: "Future Implementation", value: "future implementation", color: "primary" },
          { label: "Unable to Replicate", value: "Unable to Replicate", color: "primary" },
          { label: "Duplicate", value: "duplicate", color: "primary" },
          { label: "Not an Issue", value: "not an issue", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "New", value: "new", color: "primary" },
          { label: "In Development", value: "in development", color: "secondary" },
          { label: "Development Completed", value: "development completed", color: "success" },
          { label: "Analysis/Design", value: "analysis/design", color: "primary" },
          { label: "Review Completed", value: "review completed", color: "light" },
          { label: "Ready For Testing", value: "ready for testing", color: "warning", },
          { label: "Need Clarification", value: "need clarification", color: "dark" },
          { label: "Reopen", value: "reopen", color: "danger" },
          { label: "Resolved", value: "resolved", color: "info" },
          { label: "Moved to Prod", value: "moved to prod", color: "primary" },
          { label: "On Hold", value: "onhold", color: "primary" },
          { label: "Future Implementation", value: "future implementation", color: "primary" },
          { label: "Unable to Replicate", value: "Unable to Replicate", color: "primary" },
          { label: "Duplicate", value: "duplicate", color: "primary" },
          { label: "Not an Issue", value: "not an issue", color: "primary" },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        fieldName: "taskStatus",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "screen",
        type: "text",
        placeholder: "Screen",
        label: "Screen/Module Name",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "screen=undefined",
        capitalizeTableText: true,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "screen",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "screen",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "startDate",
        type: "date",
        value: new Date(),
        placeholder: "startDate",
        label: "start Date",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 8,
        derivedValue: "startDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "endDate",
        type: "date",
        placeholder: "endDate",
        label: "end Date",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 9,
        derivedValue: "endDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "endDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "estimatedHours",
        type: "number",
        placeholder: "estimatedHours",
        label: "estimated Hours",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 10,
        derivedValue: "estimatedHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "estimatedHours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "estimatedHours",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "image",
        type: "profile",
        placeholder: "File",
        label: "Attach File",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 14,
        derivedValue: "file=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        imagePath: "Task",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "actualWorkingHours",
        type: "number",
        placeholder: "WorkingHours",
        label: "Worked Hours",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue: "actualWorkingHours=workedHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "actualWorkingHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isEditFieldDisable: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "description",
        type: "ckeditor",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 20,
        editFormOrder: 20,
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "description",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "sprint",
        type: "relateAutoComplete",
        placeholder: "Sprint Name",
        label: "Sprint Name",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "sprint=sprint=sprint=sprintName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "sprint",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1035,
        hasDependency: true,
        fieldForKey: "projectId",
        hasDependencyField: "projectId",
        searchField: "name",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "sprint",
        searchApi: "sprints",
        isMultiple: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
  };

  const getMobileTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        fieldName: "createdByName",
        controllerName: "employee",
        searchApi: "employees",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectName",
        type: "relateAutoComplete",
        placeholder: "Projectname",
        label: "Project",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Project",
        derivedValue:
          "projectId=projects=Projects=Projects=Projects=Projects=Projects=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "projectName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Project",
        hasDependency: "false",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["ProjectName"],
        controllerName: "Project",
        searchApi: "projects",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "projectId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskSummary",
        type: "text",
        placeholder: "taskSummary",
        label: "taskSummary",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "taskSummary",
        derivedValue:
          "taskSummary=TaskSummary=TaskSummary=TaskSummary=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskSummary",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "priority",
        type: "dropDown",
        placeholder: "priority",
        label: "priority",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "priority",
        derivedValue: "priority=priority=Priority=Priority=Priority=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        actions: [],
        filter: true,
        actionsNumber: [],
        id: "priority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Blocker", value: "Blocker", color: "primary" },
          { label: "Major", value: "Major", color: "primary" },
          { label: "Minor", value: "Minor", color: "primary" },
          { label: "Critical", value: "Critical", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Blocker", value: "blocker", color: "primary" },
          { label: "Major", value: "major", color: "primary" },
          { label: "Minor", value: "minor", color: "primary" },
          { label: "Critical", value: "critical", color: "primary" },
        ],
        defaultValues: ['critical', 'major', 'minor', 'blocker'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "priority",
        showOrHideFields: [],
        fieldName: "priority",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        type: "dropDown",
        placeholder: "type",
        value: "new",
        label: "type",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "type",
        derivedValue: "type=type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "New", value: "New", color: "primary" },
          { label: "Defect", value: "Defect", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "New", value: "new", color: "primary" },
          { label: "Defect", value: "defect", color: "secondary" },
        ],
        defaultValues: ['new', 'defect'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedTo",
        type: "relateAutoComplete",
        placeholder: "Assigned To",
        label: "Assigned To",
        width: "150px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Assigned To",
        derivedValue: "AssignedTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "assignedTo",
        showOrHideFields: [],
        fieldName: "assignedTo",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Status",
        derivedValue:
          "status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "Active", value: "Active", color: "success" },
          { label: "Inactive", value: "Inactive", color: "info" },
          { label: "Completed", value: "Completed", color: "dark" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "Active", value: "Active", color: "success" },
          { label: "Inactive", value: "Inactive", color: "info" },
          { label: "Completed", value: "Completed", color: "dark" },
        ],
        defaultValues: ['Pending', 'Active', 'Inactive', 'Completed'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "taskID",
        type: "uniqueID",
        placeholder: "TaskID",
        label: "Task ID",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Task ID",
        derivedValue: "taskID=task_id=task_id=task_id=task_id=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskID",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "6",
        textAlign: "Center",
        show: true,
        field: "taskID",
        showOrHideFields: [],
        fieldName: "taskID",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "taskStatus",
        type: "dropDown",
        placeholder: "TaskStatus",
        value: "New",
        label: "TaskStatus",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "TaskStatus",
        derivedValue:
          "taskStatus=taskStatus=taskStatus=taskStatus=task_status=task_status=task_status=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "taskStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "New", value: "new", color: "primary" },
          { label: "In Development", value: "in development", color: "secondary" },
          { label: "Development Completed", value: "development completed", color: "success" },
          { label: "Analysis/Design", value: "analysis/design", color: "primary" },
          { label: "Review Completed", value: "review completed", color: "light" },
          { label: "Ready For Testing", value: "ready for testing", color: "warning", },
          { label: "Need Clarification", value: "need clarification", color: "dark" },
          { label: "Reopen", value: "reopen", color: "danger" },
          { label: "Resolved", value: "resolved", color: "info" },
          { label: "Moved to Prod", value: "moved to prod", color: "primary" },
          { label: "On Hold", value: "onhold", color: "primary" },
          { label: "Future Implementation", value: "future implementation", color: "primary" },
          { label: "Unable to Replicate", value: "unable to replicate", color: "primary" },
          { label: "Duplicate", value: "duplicate", color: "primary" },
          { label: "Not an Issue", value: "not an issue", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "New", value: "new", color: "primary" },
          { label: "In Development", value: "in development", color: "secondary" },
          { label: "Development Completed", value: "development completed", color: "success" },
          { label: "Analysis/Design", value: "analysis/design", color: "primary" },
          { label: "Review Completed", value: "review completed", color: "light" },
          { label: "Ready For Testing", value: "ready for testing", color: "warning", },
          { label: "Need Clarification", value: "need clarification", color: "dark" },
          { label: "Reopen", value: "reopen", color: "danger" },
          { label: "Resolved", value: "resolved", color: "info" },
          { label: "Moved to Prod", value: "moved to prod", color: "primary" },
          { label: "On Hold", value: "onhold", color: "primary" },
          { label: "Future Implementation", value: "future implementation", color: "primary" },
          { label: "Unable to Replicate", value: "unable to replicate", color: "primary" },
          { label: "Duplicate", value: "duplicate", color: "primary" },
          { label: "Not an Issue", value: "not an issue", color: "primary" },
        ],
        defaultValues: ['new', 'in development', 'development completed', 'ready for testing', 'analysis/design', 'resolved', 'reopen', 'onhold', 'future implementation', 'moved to prod', 'duplicate', 'unable to replicate', 'review completed', 'need clarification', 'not an issue'],
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "taskStatus",
        showOrHideFields: [],
        fieldName: "taskStatus",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "screen",
        type: "text",
        placeholder: "Screen",
        label: "Screen",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 4,
        header: "Screen",
        derivedValue: "screen=undefined",
        capitalizeTableText: true,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "screen",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "screen",
        showOrHideFields: [],
        fieldName: "screen",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "startDate",
        type: "date",
        placeholder: "startDate",
        label: "startDate",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "startDate",
        derivedValue: "startDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "startDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "endDate",
        type: "date",
        placeholder: "endDate",
        label: "endDate",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "endDate",
        derivedValue: "endDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "endDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "endDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "estimatedHours",
        type: "number",
        placeholder: "estimatedHours",
        label: "estimatedHours",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "estimatedHours",
        derivedValue: "estimatedHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "estimatedHours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "estimatedHours",
        showOrHideFields: [],
        fieldName: "estimatedHours",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "image",
        type: "profile",
        placeholder: "File",
        label: "Attach File",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Attach File",
        derivedValue: "file=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        imagePath: "Task",
        textAlign: "Center",
        show: true,
        field: "image",
        showOrHideFields: [],
        fieldName: "image",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "actualWorkingHours",
        type: "number",
        placeholder: "WorkingHours",
        label: "Worked Hours",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Worked Hours",
        derivedValue: "actualWorkingHours=workedHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "actualWorkingHours",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "actualWorkingHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "description",
        showOrHideFields: [],
        fieldName: "description",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sprint",
        type: "text",
        placeholder: "Sprint Name",
        label: "Sprint Name",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "Sprint Name",
        derivedValue: "sprint=sprintName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "sprint",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "sprint",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
    ];
    return data;
  };

  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

  };

  const saveDataToServer = async (item, field, value, getDataFromServer) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.tasks}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            getDataFromServer();
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={true}
          printRequried={true}
          actionsTypes={[
            {
              name: "Delete",
              options: [
                {
                  label: "Delete",
                  value: "Delete",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },
            {
              name: "ready for testing",
              action: saveDataToServer,
              options: [
                {
                  label: "Ready For Testing",
                  field: "taskStatus",
                  value: "ready for testing",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },
            {
              name: "resolved",
              action: saveDataToServer,
              options: [
                {
                  label: "Resolved",
                  field: "taskStatus",
                  value: "resolved",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },
            {
              name: "moved to prod",
              action: saveDataToServer,
              options: [
                {
                  label: "Moved to Prod",
                  field: "taskStatus",
                  value: "moved to prod",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            }
          ]}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.tasks}
          globalSearch={
            "taskSummary/priority/type/status/taskStatus/screen/description"
          }
          displayName="Tasks"
          type="Tasks"
          routeTo={apiCalls.tasks}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.tasks.toLowerCase()}
          apiUrl={apiCalls.tasks}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="tasks"
          apiUrl={apiCalls.tasks}
        />
      ) : null}
    </span>
  );
};

export default Tasks;