import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Inventories from './components/Inventories';

const inventories = (props,{ t }) => (
  <Container>
    <Inventories {...props} />
  </Container>
);

inventories.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(inventories);
