import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Projects = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();

  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Projects");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "name",
        label: "name",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "name",
        derivedValue:
          "name=ProjectName=ProjectName=ProjectName=ProjectName=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
      },
      {
        name: "startDate",
        type: "date",
        placeholder: "startDate",
        label: "Start Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Start Date",
        derivedValue: "startDate=StartDate=StartDate=StartDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        filter: true,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "startDate",
        showOrHideFields: [],
        fieldName: "startDate",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "teamLead",
        type: "relateAutoComplete",
        placeholder: "teamLead",
        label: "Team Lead",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Team Lead",
        derivedValue: "teamLead=TeamLead=TeamLead=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "teamLead",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "Center",
        filter: true,
        show: true,
        field: "teamLead",
        showOrHideFields: [],
        fieldName: "teamLead",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "status",
        value: "Active",
        label: "status",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Status",
        derivedValue:
          "status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "Closed", value: "Closed", color: "danger" },
        ],
        filter: true,
        filterElement: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "Closed", value: "Closed", color: "warning" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Actions",
        type: "checkbox",
        placeholder: "Actions",
        label: "Actions",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Actions",
        derivedValue: "Actions=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Actions",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        displayinServer: "True",
        serverFieldType: "String",
        show: true,
        field: "Actions",
        fieldType: "Actions",
        showOrHideFields: [],
        fieldName: "Actions",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "image",
        type: "profile",
        placeholder: "attachFile",
        label: "attachFile",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Attach File",
        derivedValue: "attachFile=AttachFile=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        imagePath: "Project",
        fieldType: "image",
        textAlign: "Center",
        show: true,
        field: "image",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "description",
        label: "Description",
        width: "200px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Description",
        derivedValue: "description=Description=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        style: { width: '500px', textOverflow: "ellipsis", overflow: "hidden" },
        show: true,
        filter: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "assignEmployees",
        type: "relateAutoComplete",
        placeholder: "assignEmployees",
        label: "Assign Employees",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Team Members",
        derivedValue:
          "assignEmployees=assignEmployees=Team_Members=Team_Members=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "assignEmployees",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "displayName",
        filter: true,
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: true,
        textAlign: "Center",
        style: { width: '300px', textOverflow: "ellipsis", overflow: "hidden" },
        show: true,
        field: "assignEmployees",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "teamSize",
        type: "number",
        placeholder: "teamSize",
        label: "Team Size",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Team Size",
        derivedValue: "teamSize=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teamSize",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "teamSize",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "prefix",
        type: "text",
        placeholder: "prefix",
        label: "Project Key",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Project Key",
        derivedValue: "prefix=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "prefix",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "prefix",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "clientName",
        type: "text",
        placeholder: "clientName",
        label: "Client Name",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Client Name",
        derivedValue: "clientName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clientName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "clientName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "clientPhoneNumber",
        type: "text",
        placeholder: "clientPhoneNumber",
        label: "Client PhoneNumber",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Client PhoneNumber",
        derivedValue: "clientPhoneNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clientPhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "clientPhoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "clientAddress",
        type: "textarea",
        placeholder: "clientAddress",
        label: "Client Address",
        width: "200px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Client Address",
        derivedValue: "clientAddress=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clientAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "clientAddress",
        showOrHideFields: [],
        fieldName: "clientAddress",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "showsTaskId",
        type: "radio",
        placeholder: "showsTaskId",
        label: "Show TaskId",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "Show TaskId",
        derivedValue: "showsTaskId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "showsTaskId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        show: true,
        field: "showsTaskId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {

    return [

      {
        name: "name",
        type: "text",
        placeholder: "name",
        label: "Project Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue:
          "name=ProjectName=ProjectName=ProjectName=ProjectName=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: true,
        displayineditForm: true,
        displayinlist: true,
        isFieldRequired: true,
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        validationRequired: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "startDate",
        type: "date",
        placeholder: "startDate",
        label: "start Date",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "startDate=StartDate=StartDate=StartDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        displayinaddForm: true,
        displayineditForm: true,
        displayinlist: true,
        isFieldRequired: true,
        required: true,
        displayOptionsInActions: false,
        globalSearchField: false,
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        fieldName: "startDate",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "teamLead",
        type: "relateAutoComplete",
        placeholder: "teamLead",
        label: "team Lead",
        width: "150px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "teamLead=TeamLead=TeamLead=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "teamLead",
        displayinaddForm: true,
        displayineditForm: true,
        displayinlist: true,
        isFieldRequired: true,
        required: true,
        displayOptionsInActions: false,
        globalSearchField: false,
        controllerId: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "teamLead",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "status",
        value: "Active",
        label: "status",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue:
          "status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: true,
        displayineditForm: true,
        displayinlist: true,
        isFieldRequired: false,
        required: false,
        displayOptionsInActions: false,
        globalSearchField: true,
        controllerId: null,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "Closed", value: "Closed", color: "danger" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
      {
        name: "image",
        type: "profile",
        placeholder: "attachFile",
        label: "attachFile",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "attachFile=AttachFile=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "attachFile",
        displayinaddForm: true,
        displayineditForm: true,
        displayinlist: true,
        isFieldRequired: false,
        required: false,
        fieldType: "file",
        displayOptionsInActions: false,
        globalSearchField: false,
        controllerId: null,
        imagePath: "Project",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "description",
        label: "description",
        width: "200px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "description=Description=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: true,
        displayineditForm: true,
        displayinlist: true,
        isFieldRequired: false,
        required: false,
        displayOptionsInActions: false,
        globalSearchField: true,
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "assignEmployees",
        type: "relateAutoComplete",
        placeholder: "assignEmployees",
        label: "Team Members",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue:
          "assignEmployees=assignEmployees=Team_Members=Team_Members=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "assignEmployees",
        displayinaddForm: true,
        displayineditForm: true,
        displayinlist: false,
        isFieldRequired: true,
        required: true,
        displayOptionsInActions: false,
        globalSearchField: false,
        controllerId: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
        isMultiple: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "prefix",
        type: "text",
        placeholder: "prefix",
        label: "Project Key",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "prefix=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "prefix",
        displayinaddForm: true,
        displayineditForm: true,
        displayinlist: true,
        isFieldRequired: true,
        required: true,
        displayOptionsInActions: false,
        globalSearchField: true,
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "clientName",
        type: "text",
        placeholder: "clientName",
        label: "Client Name",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "clientName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clientName",
        displayinaddForm: true,
        displayineditForm: true,
        displayinlist: false,
        isFieldRequired: false,
        required: false,
        displayOptionsInActions: false,
        globalSearchField: true,
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "clientPhoneNumber",
        type: "text",
        placeholder: "clientPhoneNumber",
        label: "Client PhoneNumber",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "clientPhoneNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clientPhoneNumber",
        displayinaddForm: true,
        displayineditForm: true,
        displayinlist: false,
        isFieldRequired: false,
        required: false,
        displayOptionsInActions: false,
        globalSearchField: true,
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "clientAddress",
        type: "textarea",
        placeholder: "clientAddress",
        label: "Client Address",
        width: "200px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "clientAddress=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clientAddress",
        displayinaddForm: true,
        displayineditForm: true,
        displayinlist: true,
        isFieldRequired: false,
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "clientAddress",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "showsTaskId",
        type: "radio",
        placeholder: "showsTaskId",
        label: "Show TaskId ?",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "showsTaskId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "showsTaskId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
  };

  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "name",
        label: "name",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "name",
        derivedValue:
          "name=ProjectName=ProjectName=ProjectName=ProjectName=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
      },
      {
        name: "startDate",
        type: "date",
        placeholder: "startDate",
        label: "Start Date",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Start Date",
        derivedValue: "startDate=StartDate=StartDate=StartDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "startDate",
        filter: true,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "startDate",
        showOrHideFields: [],
        fieldName: "startDate",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "teamLead",
        type: "relateAutoComplete",
        placeholder: "teamLead",
        label: "Team Lead",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Team Lead",
        derivedValue: "teamLead=TeamLead=TeamLead=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "teamLead",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "Center",
        filter: true,
        show: true,
        field: "teamLead",
        showOrHideFields: [],
        fieldName: "teamLead",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "status",
        value: "Active",
        label: "status",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Status",
        derivedValue:
          "status=Status=Status=Status=Status=Status=Status=Status=Status=Status=Status=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "Closed", value: "Closed", color: "danger" },
        ],
        filter: true,
        filterElement: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "danger" },
          { label: "InActive", value: "InActive", color: "warning" },
        ],
        defaultValues: ['Active', 'Pending'],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Actions",
        type: "checkbox",
        placeholder: "Actions",
        label: "Actions",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Actions",
        derivedValue: "Actions=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Actions",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        displayinServer: "True",
        serverFieldType: "String",
        show: true,
        field: "Actions",
        fieldType: "Actions",
        showOrHideFields: [],
        fieldName: "Actions",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "image",
        type: "profile",
        placeholder: "attachFile",
        label: "attachFile",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Attach File",
        derivedValue: "attachFile=AttachFile=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "image",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        imagePath: "Project",
        fieldType: "image",
        textAlign: "Center",
        show: true,
        field: "file",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "description",
        label: "Description",
        width: "200px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Description",
        derivedValue: "description=Description=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        filter: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "assignEmployees",
        type: "relateAutoComplete",
        placeholder: "assignEmployees",
        label: "Assign Employees",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Team Members",
        derivedValue:
          "assignEmployees=assignEmployees=Team_Members=Team_Members=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        actions: [],
        actionsNumber: [],
        id: "assignEmployees",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "employee",
        searchField: "displayName",
        filter: true,
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: true,
        textAlign: "Center",
        show: true,
        field: "assignEmployees",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "teamSize",
        type: "number",
        placeholder: "teamSize",
        label: "Team Size",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Team Size",
        derivedValue: "teamSize=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teamSize",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "teamSize",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "prefix",
        type: "text",
        placeholder: "prefix",
        label: "Project Key",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Project Key",
        derivedValue: "prefix=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "prefix",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "prefix",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "clientName",
        type: "text",
        placeholder: "clientName",
        label: "Client Name",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Client Name",
        derivedValue: "clientName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clientName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "clientName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "clientPhoneNumber",
        type: "text",
        placeholder: "clientPhoneNumber",
        label: "Client PhoneNumber",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Client PhoneNumber",
        derivedValue: "clientPhoneNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clientPhoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "clientPhoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "clientAddress",
        type: "textarea",
        placeholder: "clientAddress",
        label: "Client Address",
        width: "200px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Client Address",
        derivedValue: "clientAddress=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "clientAddress",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "clientAddress",
        showOrHideFields: [],
        fieldName: "clientAddress",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "showsTaskId",
        type: "radio",
        placeholder: "showsTaskId",
        label: "Show TaskId",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "Show TaskId",
        derivedValue: "showsTaskId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "showsTaskId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
        fieldType: "radio",
        show: true,
        field: "showsTaskId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
    ];
    return data;
  };

  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const UpdateStatussubmit1 = (item) => {
    let x = ["Status"];
    let objprojects = {};

    objprojects["_id"] = item[0]["_id"];
    for (let x2 of x) {
      objprojects[x2] = item[0][x2];
    }
    let formFields = this.getFormFields();

    formFields = formFields.filter((y) => x.includes(y.name));

    this.setState({
      openNewUserModal: true,
      item: objprojects,
      newFormFields: formFields,
    });
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

  };

  const saveDataToServer = async (item, field, value, getDataFromServer) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.projects}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            getDataFromServer();
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={true}
          printRequried={true}
          actionsTypes={[
            {
              name: "Delete",
              options: [
                {
                  label: "Delete",
                  value: "Delete",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },
            {
              name: "Pending",
              action: saveDataToServer,
              options: [
                {
                  label: "Pending",
                  field: "status",
                  value: "Pending",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: false,
                },
              ],
            },
            {
              name: "Active",
              action: saveDataToServer,
              options: [
                {
                  label: "Active",
                  field: "status",
                  value: "Active",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: false,
                },
              ],
            },
            {
              name: "Closed",
              action: saveDataToServer,
              options: [
                {
                  label: "Closed",
                  field: "status",
                  value: "Closed",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: false,
                },
              ],
            },
          ]}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.projects}
          globalSearch={
            "name/status/description/companyEmpId/prefix/clientName/clientPhoneNumber/clientAddress/showsTaskId/teamLeadName"
          }
          displayName="Projects"
          type="Projects"
          routeTo={apiCalls.projects}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.projects}
          apiUrl={apiCalls.projects}
          selectedId={params.id}
          defaultCriteria={[
            { key: "status", value: ["Active", "Pending"], type: "in" },
          ]}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="projects"
          apiUrl={apiCalls.projects}
        />
      ) : null}
    </span>
  );
};

export default Projects;