import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, ModalHeader, ModalBody,
  Card, CardBody, ButtonToolbar, ButtonGroup, Row, Col, Badge
} from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import Moment from 'moment';
import moment from 'moment'
import { load as loadAccount } from './../../redux/reducers/commonReducer';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes, { element } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PaginatorComponent from '../Cruds/CommonDataTable/PaginatorComponent';

//components 
import AutoComplete from './components/AutoComplete';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import Select from '../../shared/components/form/Select';
import RadioButton from '../../shared/components/form/RadioButton';
import DefaultInput from '../../shared/components/form/DefaultInput';
import DefaultTextArea from '../../shared/components/form/DefaultTextArea';
import DatePicker from '../../shared/components/form/DatePicker';
import TimePicker from '../../shared/components/form/TimePicker';
import renderRadioButtonField from '../../shared/components/form/RadioButton';
import UserPasswordResetModal from '../Cruds/CommonModals/UserPasswordResetModal';
import { Dropdown } from 'primereact/dropdown';
import RenderFileInputField from '../Form/components/FileUpload';
// import {CKEditor} from 'ckeditor4-react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import TextEditorTwo from '../../shared/components/TextEditor';

import config from '../../config/config';
import configMessages from '../../config/configMessages';
import apiCalls from '../../config/apiCalls';
import fetchMethodRequest from '../../config/service';
import DataTables from '../Cruds/CommonDataTable/DataTable';
// Toaster message
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import EyeIcon from 'mdi-react/EyeIcon';
import dateFormats from '../UI/FormatDate/formatDate';
import validate from '../Validations/validate';

// Multi select Dropdown
import MultiSelectDropDown from './components/MultiSelect';

// Loader
import Loader from '../App/Loader';
// Calendar
//session expiry modal
import DeleteRowModal from '../Cruds/CommonModals/DeleteRowModal';
import SessionExpiryModal from '../Cruds/CommonModals/SessionexpiryModal'
//import TicketCommentsInfo from '../Tables/PrimeReactTable/Tickets/components/TicketCommentsInfo';
import { th } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Scrollbar from 'smooth-scrollbar-react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import DoInputField from "./Fields/DoInputField";
import DoPasswordField from "./Fields/DoPasswordField";
import DoDateField from './Fields/DoDateField';
import DoSelectField from './Fields/DoSelectField';
import DoTextareaField from './Fields/DoTextareaField';
import DoMultiSelectField from './Fields/DoMultiSelectField';
import DoRadioButtons from './Fields/DoRadioButtons';
import DoCheckboxField from './Fields/DoCheckboxField';
import DoAutoCompleteField from './Fields/DoAutoCompleteField';
import DoCKEditorField from './Fields/DoEditor';
import { getPasswordRegex } from './DoValidations';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DoFileUpload from './Fields/DoFileUpload';
import WorkedHoursModal from '../Cruds/CommonModals/WorkedHoursModal';
import HistoryView from './HistoryView';

let radioRequired = value => {
  let error = undefined;
  if (value || typeof value === 'string') {
    error = undefined
  } else {
    error = configMessages.fillRadio
  }
  return error
}
let required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);
let normalizePhone = (value) => {
  if (!value) {
    return value
  }
  let onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}


let FormModal = (props) => {

  let [displayBreadCrumbValue, setDisplayBreadCrumbValue] = useState(props.displayBreadCrumbValue);
  let [displayBreadCrumbField, setDisplayBreadCrumbField] = useState(props.displayBreadCrumbField);
  let [userStatus, setUserStatus] = useState(props.userStatus);
  let [formFields, setFormFields] = useState([]);
  let [formValues, setFormValues] = useState({});
  let [colored, setColored] = useState(false);
  let [header, setHeader] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  let [defaultValue, setDefaultValue] = useState(true);
  let [roleOptions, setRoleOptions] = useState([]);
  let [slno, setSslno] = useState(0);
  let [rowData, setRowData] = useState('');
  let [originalTableFields, setOriginalTableFields] = useState(props.originalTableFields);
  let [actions, setActions] = useState('');
  let [confirmType, setConfirmType] = useState('');
  let [confirmModalText, setConfirmModalText] = useState('');
  let [activitiesData, setActivitiesData] = useState([]);
  let [totalRecordsLength, setTotalRecordsLength] = useState(0);
  let [first, setFirst] = useState(0);
  let [rows, setRows] = useState(10);
  let [page, setPage] = useState(1)
  let [showorHideLevelsField, setShoworHideLevelsField] = useState(false);
  let [filterCriteria, setFilterCriteria] = useState({ limit: 10, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
  let [editRowDataID, setEditRowDataID] = useState();
  let [formType, setFormType] = useState(props.formType);
  let [userData, setUserData] = useState();
  let [menuList, setMenuList] = useState(props.menuList ? props.menuList : []);
  let [selectActions, setSelectActions] = useState();
  let [selectedRows, setSelectedRows] = useState();
  let [sortCount, setSortCount] = useState();

  let [sessionExpiryModal, setSessionExpiryModal] = useState(false);
  let [openUserPasswordResetModal, setOpenUserPasswordResetModal] = useState(false);
  let [openDeleteModal, setOpenDeleteModal] = useState(false);
  let [openCommentModal, setOpenCommentModal] = useState(false);
  let [schema, setSchema] = useState('');
  let [openWorkedHoursModal, setOpenWorkedHoursModal] = useState(false);
  let [editorData, setEditorData] = useState('');
  let [isSaveAndAdd, setIsSaveAndAdd] = useState(false);
  let [visible, setVisible] = useState(false);
  let [projectsList, setProjectsList] = useState([]);
  let [isSaveDisable, setIsSaveDisable] = useState(false);
  let [getProjectsList, setGetProjectsList] = useState([]);
  let [selectedProjectEmp, setSelectedProjectEmp] = useState([]);
  let [showLeaveHalfDayField, setShowLeaveHalfDayField] = useState(false);
  let [showLeaveToDateField, setShowLeaveToDateField] = useState(true)
  let [workedHours, setWorkedHours] = useState('');
  let [selectedDataSno, setSelectedDataSno] = useState('');

  let loginRole = JSON.parse(localStorage.getItem('loginCredentials'))
  let assignEmployees;

  useEffect(() => {

    /**@Changing indexValue of the modal */

    const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
    if (modal) {
      modal.style.zIndex = '300';
    }

    const formData = props.formFields();
    // const formData = props.formFields();
    // const requiredFields = [];
    // formData.forEach(field => {
    // if (('isFieldRequired' in field && field.isFieldRequired==="true") || ('validationRequired' in field && field.validationRequired==true)) {
    //   requiredFields.push(field);
    // }
    //   if ('required' in field && field.required==true) {

    //     requiredFields.push(field);
    //   }
    // });
    let tschema = setValidations(props.formFields());
    setSchema(tschema)
    let sessionexpired = localStorage.getItem('sessionexpired');
    if (sessionexpired) {
      setSessionExpiryModal(true);
    }
    getActivities();
    //Because of setTimeout worked hours not loading for tasks edit form 
    if (props.type !== 'Tasks') {
      let timeOut = props.addSelectedRecord ? 0 : 500;
      setTimeout(() => {
        getFormFields();
      }, timeOut);
    } else {
      getFormFields();
    }
    let recordId = props.selectedId ? props.selectedId : props.tableRowData._id;
    let selectedData = props.formType !== 'add' ? getSelectedData(recordId, props.allUsersData) : {};
    setSelectedDataSno(selectedData.Sno)
    if (props.formType === 'view') {
      // handleViewDisplay(getSelectedData(props.selectedId,props.allUsersData), 'view')
      handleNextAndBackActions(selectedData.Sno - 1);
    }
    else if (props.formType === 'edit') {
      getRowData(selectedData, 'edit');
    }

    //This is for correct working of forms when fields are provided with default values
    if (props.formType === 'add') {
      let fields = props.formFields();
      fields.forEach((item) => {
        if (item.value && item.name) {
          setValue(item.name, item.value)
        }
      })
    }
    if (props.formType !== 'view') {
      formData.map((obj) => {
        if ((obj.value || obj.value === false) && obj.name && !selectedData[obj.name]) {
          setValue(obj.name, obj.value)
        }
      })
    }
    if (props.type === 'Employees') {
      getProjects();
    }
    getProjectsEmployees();
  }, []);


  useEffect(() => {
    if (props.type === "Roles") {
      const setInitialValues = () => {
        setValue('permission', {});
        // setValue('levels', 1);
        const permissionsData = generatePermissionsData();
        if (permissionsData && permissionsData.length > 0) {
          permissionsData.forEach(screen => {
            if (screen && screen.name) {
              setValue(screen.name, screen.value);
            }
          });
        }
      };
      setInitialValues();
    }
  }, [props.selectedId, props.allUsersData]);

  useEffect(() => {
    if (workedHours && workedHours != '') {
      getFormFields();
    }
  }, [workedHours])

  let {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });


  const getSelectedData = (id, allData) => {
    if (id && id.startsWith('"') && id.endsWith('"')) {
      id = JSON.parse(id);
      // Parse id as a JSON string
    }
    // const data = allData.filter((row) => row._id === JSON.parse(id));
    const data = allData.filter((row) => row._id === id);
    return (data.length > 0) ? data[0] : [];
  }

  let getProjectsEmployees = () => {
    let filterCriteria = {
      limit: 20,
      page: 1,
      criteria: [{ "key": "status", "value": ['Pending', 'Active'], "type": "in" }],
    }
    let url = `${'projects'}?filter=${JSON.stringify(filterCriteria)}`;
    return fetchMethodRequest('GET', url)
      .then(async (response) => {
        if (response) {
          setGetProjectsList(response.projects)
        }
      }).catch((err) => {
        return err
      })
  }

  let setValidations = (fields) => {
    let valObj = {};
    fields.forEach((field) => {
      if (!field.isAddFormHidden && props.formType === 'add' && field.required) {
        valObj[field.name] = getValidations(field);
      }

      if (('isFieldRequired' in field && field.isFieldRequired === "true") || ('validationRequired' in field && field.validationRequired == true)) {
        valObj[field.name] = getValidations(field);
      }
      if (field.type && field.type == "email") {
        valObj[field.name] = getValidations(field);
      }
    });
    return yup.object().shape(valObj)
  }

  let getValidations = (field) => {

    if (field.type === 'number') {
      return yup.number().required(`${field.label} is a required field`);
    } else if (field.type === 'email' && field.required && field.required == true) {
      return yup.string().email().required(`${field.label} is a required field`);
    } else if (field.type === 'password') {
      return yup.string().required().matches(getPasswordRegex(), "Password must contain at least 8 characters, one uppercase, one number and one special case character")
    } else if ((field.type === "relateAutoComplete" && field.isMultiple) || (field && field.isMultiSelect && field.type == 'dropDown')) {
      return yup.array().required(`${field.label} is a required field`)
    } else if (field.type === "relateAutoComplete" || field.type === "permission") {
      return yup.object().required(`${field.label} is a required field`)
    }
    /**@CheckBox */
    else if (field.type === 'checkbox') {
      if (field.required) {
        return yup.boolean().oneOf([true], 'Checkbox must be checked')
      } else {
        yup.boolean();
      }
    }

    //regex pattern matching validation for required fields
    else if (field.type === 'text' && field.regexPattern && field.required == true) {
      return yup.string().required(`${field.label} is a required field`).test({
        name: 'check-regex',
        test: function (value, { originalValue }) {
          if (originalValue) {
            const reg = new RegExp(field.regexPattern);
            if (!reg.test(originalValue)) {
              const customErrorMessage = disp(field);
              throw this.createError({ path: field.name, message: customErrorMessage });
            }
          }
          return true;
        },
      });
    }

    //regex pattern matching validation for not required fields
    else if (field.type === 'text' && field.regexPattern) {
      return yup.string().test({
        name: 'check-regex',
        test: function (value, { originalValue }) {
          if (originalValue) {
            const reg = new RegExp(field.regexPattern);
            if (!reg.test(originalValue)) {
              const customErrorMessage = disp(field);
              throw this.createError({ path: field.name, message: customErrorMessage });
            }
          }
          return true;
        },
      });
    }

    //email validation when not required field
    else if (field.type === 'email') {
      return yup.string().test({
        name: 'email-regex',
        test: function (value, { originalValue }) {
          if (originalValue) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(originalValue)) {
              throw this.createError({ path: field.name, message: 'Enter a valid email id' });
            }
          }
          return true;
        },
      });
    }
    else if (field.type === 'float' && field.id === 'hours') {
      return yup.number().required(`${field.label} is a required field`).test({
        name: 'hours-validation',
        test: function (value) {
          if (value < 0 || value > 23.60) {
            throw this.createError({ message: 'Enter a value between 0 and 24' });
          }
          return true;
        },
      });
    }

    /**@Text Validation */
    // else if(field.type == 'text' && field.regexPattern){
    //   let str=disp(field);
    //   return yup.string().required(`${field.label} is a required field`).matches(new RegExp(field.regexPattern),`${str}`);   
    // }

    else if (field.type === 'WebSite') {
      const websiteRegex = /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;

      return yup.string().required(`${field.label} is a required field`).matches(websiteRegex, `Enter a valid URL`);
    }
    else if (field.type == 'uniqueField' && field.regexPattern) {
      return yup.string().required().matches(new RegExp(field.regexPattern), `Please enter valid ${field.name}`);
    }
    else if (field.type === 'date') {
      if (field.name === "joinDate") {
        return yup.date().required(`${field.label} is a required field`).when('dateOfBirth', (dateOfBirth, schema) => {
          if (dateOfBirth[0] instanceof Date) {
            return schema.min(
              new Date(new Date(dateOfBirth[0]).setFullYear(dateOfBirth[0].getFullYear() + 15)),
              'Join date must be at least 15 years after the date of birth'
            );
          } else {
            return schema;
          }
        });
      }
      return yup.date().required(`${field.label} is a required field`);
    }
    else {
      return yup.string().required(`${field.label} is a required field`);
    }
  }

  let disp = (f) => {
    let msg = "";
    let m = '';
    if (f.regexData.maxLengthRequired == true && f.regexData.showMaxField == true) {
      m = "Max length:" + f.regexData.maxLengthText + ", ";
      msg += m;
    }
    if (f.regexData.minLengthRequired == true && f.regexData.showMaxField == true) {
      m = "Min length:" + f.regexData.minLengthText + ", ";
      msg += m;
    }
    if (f.regexData.alphabetsRequired == true && f.regexData.showAlphaField == true) {
      m = "Letters" + ", ";
      msg += m;
    }
    if (f.regexData.numbersRequired == true && f.regexData.showNumField == true) {
      m = "Numbers" + ", ";
      msg += m;
    }
    if (f.regexData.showSCharField == true && f.regexData.specialCharRequired == true) {
      m = "Special characters:" + f.regexData.specialCharText + ", ";
      msg += m;
    }
    msg = msg.slice(0, -2);
    // return (
    //   <small style={{ color: '#00008B' }}>YESSSSS</small>
    // );
    return msg;

  };

  let onPageChange = async (event) => {
    let filters = { ...filterCriteria };
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filters['limit'] = event.rows;
      filters['page'] = currentPage;
      setRows(event.rows);
      setPage(event.page);
      setFirst(event.first)
    }
    await getActivities(filters);
  }

  let getTableFields = () => {
    let data = [
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 100,
        field: 'created',
        fieldType: 'Date',
        type: 'date',
        header: 'Created',
        filter: true,
        sortable: true,
        dateFormat: config.dateDayMonthFormat
      },
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 100,
        field: 'contextType',
        header: 'Context Type',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 250,
        fieldType: 'Array',
        field: 'description',
        header: 'Description',
        filter: true,
        sortable: true
      },
      // {
      //     textAlign: 'center',
      //     width: 100,
      //     field: 'ipAddress',
      //     header: 'Ip Address',
      //     filter: true,
      //     sortable: true
      // },
      // {
      //     textAlign: 'center',
      //     width: 100,
      //     field: 'deviceType',
      //     header: 'Device Type',
      //     filter: true,
      //     sortable: true
      // },
      // {
      //     textAlign: 'center',
      //     width: 100,
      //     field: 'browserName',
      //     header: 'Browser',
      //     filter: true,
      //     sortable: true
      // },
      // {
      //     textAlign: 'center',
      //     width: 100,
      //     field: 'osName',
      //     header: 'Os Name',
      //     filter: true,
      //     sortable: true
      // },
      // {
      //     textAlign: 'center',
      //     width: 100,
      //     field: 'osVersion',
      //     header: 'Os Version',
      //     filter: true,
      //     sortable: true
      // },


    ];
    return data;
  };

  let getPaginator = () => {
    return (
      <PaginatorComponent
        totalRecords={totalRecordsLength}
        first={first}
        rows={rows}
        onPageChange={onPageChange}
        isWeb={true}
      />
    )
  }

  let getActivities = async (filters) => {
    let tFilterCriteria = filters ? filters : filterCriteria;
    let urlHeading = window.location.href;
    let userID = decodeURIComponent(urlHeading.split('/').pop());
    let userIDWithoutQuotes = userID.replace(/^"(.*)"$/, '$1');
    userIDWithoutQuotes = userIDWithoutQuotes.replace(/"/g, '');
    tFilterCriteria['criteria'] = [{ key: 'contextId', value: userIDWithoutQuotes, type: 'eq' }];
    let url = `activities?filter=${JSON.stringify(tFilterCriteria)}`
    return fetchMethodRequest('GET', url).then(async (response) => {
      if (response) {
        let responseData = '',
          //  totalRecordsLength = totalRecordsLength;
          dateFormat;
        // let responseData = '', totalRecordsLength = totalRecordsLength;
        if (response && response['activities'] && response['activities'].length && response['activities'].length >= 0) {
          if (response.pagination && response.pagination.totalCount) {
            totalRecordsLength = response.pagination.totalCount;
          }
          responseData = updateDateFormat(response['activities'], dateFormat);
        } else {
          if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
            totalRecordsLength = response.pagination.totalCount;
          }
        }

        setActivitiesData(responseData);
        setTotalRecordsLength(totalRecordsLength);
        setFilterCriteria(tFilterCriteria)

      }
    }).catch((err) => {
      return err
    })
  }

  let getTableFieldItem = async (field) => {
    for (let i = 0; i < props.tablefieldsToShow.length; i++) {
      if (props.tablefieldsToShow[i].field == field) {
        return props.tablefieldsToShow[i];
      }
    }
    return null;
  }

  let getFormFields = async () => {

    if (props.formFields()) {

      let formFields = await props.formFields();
      let sortType = '';
      if (props.formType === 'add') {
        sortType = 'addFormOrder';
      } else if (props.formType === 'edit') {
        sortType = 'editFormOrder';
      }
      if (props.type == 'TimeSheets' && loginRole.roleType && loginRole.roleType == 'Employee') {
        formFields = formFields.filter((field) => {
          return (field.name != 'employee');
        })
      }
      if (props.type == 'Leaves' && loginRole.roleType && loginRole.roleType == 'Employee') {
        formFields = formFields.filter((field) => {
          return (field.name != 'employeeName');
        })
      }
      if (props.type === 'Tasks' && props.formType === 'edit') {
        if (workedHours) {
          let index = formFields.findIndex(field => field.name === 'actualWorkingHours');
          if (index !== -1 && formFields[index]) {
            formFields[index].show = true;
          }
        }
      }
      if (sortType) {
        let sortFields = [];
        let otherFields = [];
        for (let i = 0; i < formFields.length; i++) {
          if (formFields[i][sortType]) {
            sortFields.push(formFields[i]);
          } else {
            otherFields.push(formFields[i]);
          }
          // if (props.formType === 'add') {
          //   setValue(formFields[i].name, formFields[i].value);
          // }
        }
        sortFields = sortFields.sort((a, b) => a[sortType] - b[sortType]);
        formFields = [...sortFields, ...otherFields];
        formFields = formFields.sort((a, b) => a[sortType] - b[sortType]);
      }
      setFormFields(formFields);
      setValidations(formFields);

    }
  }

  let getFormFieldItem = async (key) => {
    let formFields = await props.formFields();
    for (let i = 0; i < formFields.length; i++) {
      if (formFields[i].name === key) {
        return formFields[i];
      }
    }
    return null;
  }

  //close delete modal
  let closeDeleteModal = async () => {
    setState({
      openDeleteModal: false,
      actions: ''
    })
  }

  let handleNextAndBackActions = async (index) => {
    let { allUsersData } = props;
    let data = '';
    data = allUsersData[index || index == 0 ? index : slno];
    if (formType === 'view') {
      setEditRowDataID(data['_id']);
      await handleViewDisplay(data, 'view');
    } else if (formType == 'add') {
    }
    else {
      await getRowData(data, 'edit')
    }
    setFilterCriteria({ limit: 10, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
    setFirst(0);
    setRows(10);
    setTotalRecordsLength(0)
    await getActivities();
  }

  let getViewData = async (rowData, type, rowDataIndex, userData, _id) => {
    setRowData(rowData);
    setEditRowDataID(_id);
    setFormType(type);
    setSslno(rowDataIndex);
    setUserData(userData);
  };

  let getIconValue = (rowData, labelKey) => {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }

  let handleViewDisplay = async (rowData, type) => {
    await props.getDataFromServer(props.filterCriteria)
    let _id = rowData['_id'];
    let rowDataIndex = getUserData(rowData['_id'])
    let keys = Object.keys(rowData);
    let formFields = [];
    if (formFields) {
      if (props.type) {
        if (rowData) {
          let values, fieldType, searchField, self = this, icon = false;

          // hari get all the labels from 
          keys.forEach(async function (key) {
            let labelKey = await getTableFieldItem(key);
            if (labelKey == null) {
              labelKey = key;
            } else {
              let val = rowData[key];
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              fieldType = labelKey.fieldType ? labelKey.fieldType : null
              searchField = labelKey.searchField ? labelKey.searchField : null
              let options = labelKey.options ? labelKey.options : []
              let isMul = labelKey.isMultiple ? labelKey.isMultiple : false
              labelKey = labelKey.header
              if (val) {
                if (fieldType && searchField && isMul && fieldType == "relateAutoComplete") {
                  let displayNames = rowData[key].map((item) => {
                    return item[searchField];
                  });
                  let displayNamesString = displayNames.join(', ');

                  values = {
                    label: labelKey,
                    value: icon ? val : displayNamesString,
                    fieldType: fieldType
                  }
                } else
                  if (fieldType && searchField && fieldType == "relateAutoComplete") {
                    values = {
                      label: labelKey,
                      value: icon ? val : rowData[key][searchField],
                      fieldType: fieldType
                    }
                  } else if (fieldType && fieldType == "driveLink") {
                    values = {
                      label: labelKey,
                      value:
                        <a href={`//${rowData[key]}`} target="_blank" style={{ textDecoration: "none" }}>{rowData[key]}</a>,
                      fieldType: fieldType,
                      options: options
                    }
                  } else if (fieldType && fieldType == "image") {
                    values = {
                      label: labelKey,
                      value:
                        <Link to={`${config.imgUrl}${props.type.toLowerCase()}/${rowData[key]}`} target="_blank" style={{ textDecoration: "none" }}>{rowData[key]}</Link>,
                      fieldType: fieldType,
                      options: options
                    }
                  } else {
                    values = {
                      label: labelKey,
                      value: icon ? val : rowData[key],
                      fieldType: fieldType,
                      options: options
                    }
                  }
                formFields.push(values);
                icon = false;
              }
            }
            // if (key == 'fromTime' || key == 'toTime') {
            //   let date = dateFormats.addDaysToDate(rowData[key], 1);
            //   date = dateFormats.formatDate(date, config.timeFormat);
            //   values = {
            //     label: key,
            //     value: date
            //   }
            //   formFields.push(values);
            // }
          });
        }
      }

      setFormType('view');
      setUserData(rowData);
      setDisplayBreadCrumbValue(rowData[displayBreadCrumbField]);
      setUserStatus(rowData['status'])
      await getViewData(formFields, 'view', rowDataIndex, rowData, _id);
    }
  }

  let getUserData = (_id) => {
    let data = props.allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }

  let updateDateFormat = (itemdata, dateFormat) => {
    let modifiedData = [];
    let tablefieldsToShow = getTableFields();
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tablefieldsToShow.length; k++) {
        if ("Date" == tablefieldsToShow[k]['fieldType']) {
          itemdata[i][tablefieldsToShow[k]['field']] =
            dateFormats.formatDate(
              itemdata[i][tablefieldsToShow[k]['field']],
              tablefieldsToShow[k]['dateFormat']);
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  //Get From Fields data on Edit
  let getRowData = async (selectedRowInfo, type) => {
    let keys = Object.keys(selectedRowInfo);
    for (let i = 0; i < keys.length; i++) {
      let fieldItem = await getFormFieldItem(keys[i]);
      if (fieldItem) {
        if (fieldItem.type === 'date') {
          let formattedDate = fieldItem.dateFormat ? fieldItem.dateFormat : config.dateDayMonthFormat;
          selectedRowInfo[fieldItem.name] = moment(selectedRowInfo[fieldItem.name], formattedDate).toDate();
        }
        if (fieldItem.type === 'time') {
          let formattedTime = moment(selectedRowInfo[fieldItem.name], config.fullDateTimeFormat).toDate();
          selectedRowInfo[fieldItem.name] = formattedTime;
        }
        if (fieldItem.type === 'dropDown' && fieldItem.isMultiSelect) {
          selectedRowInfo[fieldItem.name] = selectedRowInfo[fieldItem.name]
        } else if (fieldItem.type === 'dropDown' && fieldItem.dependent && fieldItem.dependent.length > 0) {
          let displayFormFields = formFields;
          if (fieldItem.dependent && fieldItem.dependent.length > 0) {
            for (let i = 0; i < fieldItem.dependent.length; i++) {
              if (selectedRowInfo && selectedRowInfo[fieldItem.name] === Object.keys(fieldItem.dependent[i])[0]) {
                if (fieldItem.dependent[i][Object.keys(fieldItem.dependent[i])[0]] && fieldItem.dependent[i][Object.keys(fieldItem.dependent[i])[0]].length > 0) {
                  displayFormFields = await showField(displayFormFields, fieldItem.dependent[i][Object.keys(fieldItem.dependent[i])[0]], true);
                }
              }
            }
            await setFormFields(displayFormFields);
          }
        }
      }
    }
    if (props.type && props.type == "Roles" && selectedRowInfo.roleType && selectedRowInfo.roleType == "Manager") {
      setShoworHideLevelsField(true);
    }
    if (props.type === 'Leaves') {
      if (selectedRowInfo.leaveDay && selectedRowInfo.leaveDay == "Half Day") {
        setShowLeaveHalfDayField(true)
      }
      if (selectedRowInfo && selectedRowInfo.halfDayType) {
        setShowLeaveToDateField(false)
      }
    }

    if (selectedRowInfo && selectedRowInfo.actualWorkingHours) {
      setWorkedHours(selectedRowInfo.actualWorkingHours)
    }
    if (props.load) {
      props.load(selectedRowInfo);
    }

    let fields = props.formFields();
    fields.forEach((item) => {
      if (!(item.isEditFormHidden === true && formType === 'edit')) {
        // setTimeout(() => {
        setValue(item.name, selectedRowInfo[item.name])
        // }, 10)
      }
    })

    setIsLoading(false);
    setEditRowDataID(selectedRowInfo._id);
    setDisplayBreadCrumbValue(selectedRowInfo[displayBreadCrumbField]);
    setFormType(type);
    setUserData(selectedRowInfo);
  }

  let closeFormModal = async () => {
    clearFormFields();
    // props.reset();
    props.getDataFromServer(props.filterCriteria);
    props.closeFormModal();
  }

  let flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]|\\/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }

  let getActivtiesTableFieldItem = (field) => {
    let tablefieldsToShow = getTableFields()
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field == field) {
        return tablefieldsToShow[i];
      }
    }
    return null;
  }

  // hari need to move to derived class or controller
  let changeFieldValues = (item, column) => {
    let self = this, tableItem;
    tableItem = self.getActivtiesTableFieldItem(column.field);
    if (tableItem.fieldType === "Array") {
      let val = flattenArray(item[column.field]);
      return <span style={tableItem.style} title={val}>
        {val}
      </span>
    } else {
      // if (item[column.field] === 0) {
      //   return item[column.field];
      // }
      if ((item[column.field]) && typeof item[column.field] !== 'object') {
        return item[column.field];
      }
    }
  }

  // form Submit
  let submit = (values) => {
    // let updateValues = {};
    if (values.roleType != "Manager") {
      values.levels = 1;
    }
    for (let formField of formFields) {
      if (formField.fieldType == "relateAutoComplete" && values[formField.name] == "") {
        values[formField.name] = null;
      }
      // updateValues[formField.name] = values[formField.name]
      if (formField.fieldType == "relateAutoComplete" && values[formField.name] && values[formField.name][formField.searchField]) {
        values[formField.name + "Search"] = values[formField.name][formField.searchField]
      }
    }
    //adding projectname for task screen 
    if (values && values.projectId && values.projectId.name) {
      values['projectName'] = values.projectId.name;
    }
    setIsSaveDisable(true);
    if (values && Object.keys(values).length > 0) {
      saveDataToServer(values);
    } else {
      return;
    }
  }

  let clearFormFields = async () => {
    if (props.load) {
      props.load({});
    }
    let formData = [...formFields];
    if (props.type === "TimeSheets") {
      let values = getValues();
      for (const property in values) {
        if (property !== 'date' && property !== "type") {
          setValue(property, '')
        }
      }
      setIsSaveAndAdd(false)
    } else {
      formData.forEach((item) => {
        item.value = '';
        item.invalid = false;
        item.message = ''
      });
      await setFormFields(formData);
    }
    getFormFields();
  }

  let onEditorChange = async (evt) => {
    setState({
      [evt.editor.name]: evt.editor.getData()
    });
  }

  let ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  //send data to server
  let saveDataToServer = async (formValues) => {

    /**@CheckBox */
    Object.keys(formValues).forEach(key => {
      if (formValues[key] === undefined) {
        let formField = formFields.filter(d => d.name == key);
        if (formField && formField[0] && formField[0].type && formField[0].type == 'checkbox') {
          formValues[key] = "false";
        }
      }
    });
    let userBody = Object.assign({}, formValues);
    let validationExists;

    setIsLoading(true);
    if (!validationExists) {
      if (formValues) {
        let method, apiUrl;
        if (formType === 'edit') {
          delete userBody.companyEmail
          delete userBody.password;
          method = 'PUT';
          apiUrl = `${props.apiUrl}/${editRowDataID}`;
        } else if (formType === 'view') {
          delete userBody.companyEmail
          delete userBody.password;
          method = 'PUT';
          apiUrl = `${props.apiUrl}/${editRowDataID}`;
        } else {
          method = 'POST';
          apiUrl = props.apiUrl;
        }

        for (let i = 0; i < formFields.length > 0; i++) {
          if (formFields[i].show === false)
            continue;
          if (formFields[i].type === 'autoComplete') {
            if (formFields[i]["options"] && formFields[i]["options"].length > 0) {
              for (let j = 0; j < formFields[i]["options"].length; j++) {
                let keys = Object.keys(formFields[i]["options"][j])
                let values = Object.values(formFields[i]["options"][j]);
                if (keys && keys[0] && values && values[0] && formValues[formFields[i]["name"]][values[0]]) {
                  userBody[keys[0]] = formValues[formFields[i]["name"]][values[0]];
                }
              }
            }
          }
          if (formFields[i].type === 'multipleprofile' || formFields[i].type === 'profile') {
            // userBody[formFields[i].name] = formFields[i].name;
            userBody[formFields[i].name] = userBody[formFields[i].name];
          }
          if (formFields[i].type === 'permission') {
            let permissions = {};
            let keys = Object.keys(formValues);
            menuList.forEach((item, index) => {
              keys.forEach((key) => {
                if (item.title === key) {
                  permissions[item.title] = formValues[key]
                }
              })
            })
            userBody.permissions = { ...permissions }
          }
          for (const key in userBody) {
            if (Object.prototype.hasOwnProperty.call(userBody, key)) {
              if (userBody[key] instanceof Date && !isNaN(userBody[key])) {
                userBody[key] = formatDate(userBody[key]);
              }
            }
          }
        }
        //Formatting dates into year month date format before sending to body
        function formatDate(dateObj) {
          const year = dateObj.getFullYear();
          const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
          const day = dateObj.getDate().toString().padStart(2, '0');
          return `${year}-${month}-${day}`;
        }

        return fetchMethodRequest(method, apiUrl, userBody)
          .then(async (response) => {
            let sessionexpired = await localStorage.getItem('sessionexpired')
            if (sessionexpired === "true") {
              setSessionExpiryModal(true);
            }
            if (response && response.respCode) {
              await props.getDataFromServer(props.filterCriteria);
              showToasterMessage(response.respMessage, 'success');
              setIsSaveDisable(false)
              if (props.displayViewOfForm === 'modal' && !isSaveAndAdd) {
                props.closeFormModal('save', response.quantityId);
              } else {
                if (formType !== 'add') {
                  window.location.href = `/${props.routeTo}`;
                } else if (!isSaveAndAdd) {
                  props.closeFormModal('save', response.quantityId);
                }
              }
              clearFormFields();
            } else if (response && response.errorMessage) {
              showToasterMessage(response.errorMessage, 'error');
              setIsSaveDisable(false)
            }

            setIsLoading(false);
          }).catch((err) => {
            return err;
          });
      } else {
        return;
      }
    }
  }

  //getStores
  let getRecords = (valve, key, apiUrl, name) => {
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: key, value: valve, type: 'eq' }];
    let url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`
    fetchMethodRequest('GET', url).then(async (response) => {
      if (response) {
        let states = response[apiUrl];
        let roleOptions = [];
        if (states && states.length > 0) {
          for (let i = 0; i < states.length; i++) {
            roleOptions.push({ label: states[i][name], value: states[i][name] })
          }

          await setRoleOptions(roleOptions);
        } else {
          await setRoleOptions([]);
        }
      }
    }).catch((err) => {
      return err
    })
  }

  let getDropdownMultiselect = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoMultiSelectField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              label={item.label}
              options={item.options}
              optionLabel={item.optionLabel}
              placeholder={item.placeholder}
            />)}
        />
      </div>
    )
  }

  let handleFnEnableControlsBasedOnValue = async (e, dependent) => {
    if (e && e == "Manager") {
      setShoworHideLevelsField(true);
    } else {
      setShoworHideLevelsField(false);
    }

    let newFormField = formFields;
    if (dependent && dependent.length > 0) {
      for (let i = 0; i < dependent.length; i++) {
        if (e && e === Object.keys(dependent[i])[0]) {
          if (dependent[i][Object.keys(dependent[i])[0]] && dependent[i][Object.keys(dependent[i])[0]].length > 0) {
            newFormField = await showField(newFormField, dependent[i][Object.keys(dependent[i])[0]], true);
          }
        }
        else {
          if (dependent[i][Object.keys(dependent[i])[0]] && dependent[i][Object.keys(dependent[i])[0]].length > 0) {
            newFormField = await showField(newFormField, dependent[i][Object.keys(dependent[i])[0]], false)
          }
        }
      }
    }
    let index = newFormField.findIndex(field => field.name === 'toDate');
    if (index !== -1 && e == 'Half Day' && newFormField[index].show && newFormField[index].show == true) {
      newFormField[index].show = false;
      newFormField[index].required = false;
      newFormField[index].isFieldRequired = false;
    } else {
      newFormField[index].show = true;
      setShowLeaveToDateField(true)
    }

    await setFormFields(newFormField)
    const schema = setValidations(newFormField);
    setSchema(schema);
  }

  let handleFnEnableControlsBasedOnValue1 = async (field, show) => {
    let newFormField = formFields;
    newFormField = await showField(newFormField, field, show);
    let requiredFields = [];
    newFormField.forEach(field => {
      if ('required' in field && field.required == true) {
        requiredFields.push(field);
      }
    });
    let tschema = setValidations(requiredFields);
    setSchema(tschema)
    await setFormFields(newFormField)
  }

  let getDropdown = (i, item) => {

    if (item.name === 'assignedTo' && props.type === 'Tasks') {
      if (getValues('projectId')) {
        let projectData = getProjectsList.filter(obj => obj.name === getValues('projectId').name);
        if (projectData && projectData.length > 0) {
          let Employees = projectData[0].assignEmployees || [];
          let teamLead = projectData[0].teamLead ? [projectData[0].teamLead] : [];

          assignEmployees = Employees.concat(teamLead);
        }
      }

      if (assignEmployees) {
        const options = assignEmployees.map(item => ({
          label: item.displayName,
          value: { displayName: item.displayName, _id: item._id }
        }));
        item.options = options;
      }
    }
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              markReq={item.required}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
              item={item}
              screenName={props.type}
              handleFnEnableControlsBasedOnValue={handleFnEnableControlsBasedOnValue}
            />)}
        />
      </div>
    )
  }

  let showField = (formFields, itemNames, show) => {

    for (let i = 0; i < formFields.length; i++) {
      if (Array.isArray(itemNames)) {
        for (let itemName of itemNames) {
          if (formFields[i].name === itemName) {
            formFields[i].show = show;
            setShowLeaveHalfDayField(show)
          }
        }
      } else {
        if (formFields[i].name === itemNames) {
          formFields[i].show = show;
          formFields[i].isAddFormHidden = !show;
        }
      }
    }
    return formFields;
  }

  const generatePermissionsData = () => {
    const options = [
      { label: 'NoView', value: 'NoView' },
      { label: 'View', value: 'View' },
      { label: 'Edit', value: 'Edit' }
    ];
    let permissions = {};
    if (props.formType === 'edit' && props.selectedId) {
      const rowData = props.allUsersData.find((obj) => obj._id === JSON.parse(props.selectedId));
      if (rowData && rowData.permissions && typeof rowData.permissions === 'object') {
        permissions = { ...rowData.permissions };
      }
    }
    else if (props.formType == "edit" && props.tableRowData) {
      let rowData = props.tableRowData;
      if (rowData && rowData.permissions && typeof rowData.permissions === 'object') {
        permissions = { ...rowData.permissions };
      }
    }

    return menuList.map((screen) => {
      return screen.name ? {
        'name': screen.name,
        'value': permissions[screen.name] ? permissions[screen.name] : 'View',
        'options': options
      } : null;
    }).filter(item => item !== null);
  };

  const getScreenPermissions = () => {
    const permissionsData = generatePermissionsData();

    return (
      <div className='row '>
        {permissionsData && permissionsData.length > 0 && permissionsData.map((item, index) => (
          <div key={index} className={`col-md-${props.displayViewOfForm === 'sidebar' ? '12' : '6'} mb-3`}>
            {getRadio(index, item)}
          </div>
        ))}
      </div>
    );
  };

  let getRolePermissions = () => {
    return (
      <div className="form form--horizontal">
        <div className="form__form-group row">
          <div className='col-sm-6' style={{ paddingLeft: '119px' }}>
            <span className='pr-4 pl-5'>{'Edit'}</span>
            <span className='pr-3 pl-2'>{'View'}</span>
            <span className='pr-2 '>{'No View'}</span>
          </div>
          <div className='col-sm-6' style={{ paddingLeft: '119px' }}>
            <span className='pr-4 pl-5'>{'Edit'}</span>
            <span className='pr-3 pl-2'>{'View'}</span>
            <span className='pr-2 '>{'No View'}</span>
          </div>
        </div>
        {menuList && menuList.length > 0 ?
          menuList.map((item, index) => {
            return <div key={index} className="form__form-group col-sm-6">
              <span className="form__form-group-label ">{item.title}</span>
              <div className="form__form-group-field ">
                {/* <Field
                  name={item.title}
                  component={renderRadioButtonField}
                  radioValue={!item.Edit ? 'Edit' : ''} />
                <Field
                  name={item.title}
                  component={renderRadioButtonField}
                  radioValue={!item.View ? 'View' : ''}
                // defaultChecked={defaultValue && index === 0 ? true : false}
                />
                <Field
                  name={item.title}
                  component={renderRadioButtonField}
                  radioValue={!item.NoView ? 'NoView' : ''}
                // defaultChecked={defaultValue && index > 0 ? true : false}
                /> */}
              </div>
            </div>
          }) : null
        }
      </div>
    );
  }

  let getProfile = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoFileUpload
              url={`uploads?uploadPath=${item.imagePath.toLowerCase()}`}
              imagePath={item.imagePath.toLowerCase()}
              formType={formType}
              showPreview={true}
              // accept = {"image/*"}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
            />)}
        />
      </div>
    )
  }

  let getFileName = async (file, item) => {
    let image = file;
    await setState({
      [item.name]: image
    });
  }

  let getDate = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoDateField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder="date"
              formType={props.formType}
              label={item.label}
              minDate={item.minDate}
              maxDate={item.maxDate}
            />)}
        />
      </div>
    )
  }

  let getTime = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoDateField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder="time"
              timeOnly={true}
            />)}
        />
      </div>
    )
  }

  let getCkEditor = (i, item) => {
    let self = this;
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoCKEditorField
              field={field}
              errors={errors}
              label={item.label}
            />
          )}
        />
      </div>

      // <span>Testing</span>
    )
  }

  let getRadio = (i, item) => {
    return (
      <div key={i}>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoRadioButtons
              markReq={item.required}
              options={item.options}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
            />)}
        />
      </div>
    )
  }

  let handleAutoData = (event) => {

    if (getValues('projectId')) {
      let projectData = getValues('projectId');

      let Employees = projectData.assignEmployees || [];
      let teamLead = projectData.teamLead ? [projectData.teamLead] : [];

      assignEmployees = Employees.concat(teamLead);
      setSelectedProjectEmp(assignEmployees)
    }
  }

  let getAutoComplete = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoAutoCompleteField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              filterField={item.filterField}
              filterValue={item.filterValue}
              filterType={item.isNotEq}
              multiple={item.isMultiple}
              fieldState={fieldState}
              errors={errors}
              screen={props.type}
              searchApi={item.searchApi}
              searchField={item.searchField}
              criteria={item.filterCriteria ? item.filterCriteria : null}
              allow={props.allowDuplicates}
              filterFieldType={item.filterFieldType ? item.filterFieldType : null}
              onChange={handleAutoData}
              placeholder={item.placeholder}
              label={item.label}
              item={item}
              setValue={setValue}
              getValues={getValues}
              handleFnEnableControlsBasedOnValue1={handleFnEnableControlsBasedOnValue1}
              formType={props.formType}
            />)}
        />
      </div>
    )
  }

  let getTextArea = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoTextareaField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }

  let getButtonGroup = (i, item) => {
    return (
      <ButtonToolbar className='mt-0'>
        <ButtonGroup size="sm" >
          {item.options.map((button) => {
            return <Button style={{ paddingTop: 3, paddingBottom: 3 }}
              color="primary"
              outline size="sm"
              active={state[`is${button.label}ReOrder`]}
              onClick={() => handleReOrdering(button.value)}>{button.label}</Button>
          })
          }
        </ButtonGroup>
      </ButtonToolbar>
    )
  }

  let getDefault = (i, item) => {
    let { t } = props;
    const isDisable = props.formType === 'add' && item.isAddFieldDisable ? true : props.formType === 'edit' && item.isEditFieldDisable ? true : false;
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoInputField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              // type={item.type ? item.type : "text"}
              type={item.type && item.type == "number" ? item.type : "text"}
              fieldState={fieldState}
              isDisable={isDisable}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }

  /**@CheckBox Component */
  let getCheckbox = (i, item) => {
    let { t } = props;
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => {
            field.value = field.value ? field.value : false;
            return <DoCheckboxField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              type={item.type ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              defaultChecked={false}
              placeholder={item.placeholder ? item.placeholder : null}
            />
          }}
        />
      </div>
    )
  }

  let getPassword = (i, item) => {
    let { t } = props;
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoPasswordField
              markReq={item.required}
              input={field}
              id={item.id ? item.id : null}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }

  let showPassword = (e) => {
    e.preventDefault();
  }

  //Function for displaying buttons
  let getButtonToolbar = () => {
    let { t } = props;
    return (
      <div className='d-flex'>
        <div className='col-12 px-0'>
          <span className='float-end'>
            <ButtonToolbar>
              {props.displayViewOfForm === 'modal' && formType === 'add' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                {t('Back') ? t('Back') : 'Back'}
              </Button> :
                props.displayViewOfForm === 'sideForm' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                  {t('Close') ? t('Close') : 'Close'}
                </Button> :
                  formType !== 'add' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                    {t('Back') ? t('Back') : 'Back'}
                  </Button> :
                    formType === 'add' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                      {t('Back') ? t('Back') : 'Back'}
                    </Button> :
                      null}
              <Button
                color='primary' outline type="submit" disabled={isSaveDisable}
                onClick={formType === 'add' && props.type === 'TimeSheets' ? () => setIsSaveAndAdd(true) : undefined}>
                {formType && formType === 'add' ?
                  t('Save') || 'Save'
                  : t('Update') || 'Update'
                }
              </Button>
              {formType && formType === 'add' && props.type === 'TimeSheets' && (
                <Button color='primary' outline type="submit" disabled={isSaveDisable}>
                  {'Save & Close'}
                </Button>
              )}

            </ButtonToolbar>
          </span>
        </div>
      </div>
    )
  }

  let getEditButton = () => {
    let { t } = props;
    return (
      <div className='mx-1 d-flex justify-content-end my-2'>
        <a href={`/edit_${props.routeTo}/${userData && userData._id ? JSON.stringify(userData._id) : ''} `} >

          <Button color='primary' outline type="submit"
          // onClick={() => getRowData(userData, 'edit')}
          >
            {t('Edit') ? t('Edit') : 'Edit'}
          </Button>

        </a>
      </div>
    )
  }

  let getModalHeader = () => {
    let { t } = props;
    return (
      <ModalHeader className="modal__header">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeFormModal} />
        <p className="bold-text  modal__title">
          {formType &&
            formType === 'edit' ?
            t('Edit') ? t('Edit') : 'Edit' :
            formType &&
              formType === 'view' ?
              t('View') ? t('View') : 'View' : t('Add') ? t('Add') : 'Add'
          }{' '}
          {props.type ? props.type : null}
        </p>
      </ModalHeader>
    )
  }

  let getDependentFields = (type, formType) => {
    return null;
  }

  let getMultiplePhotoUpload = (i, item) => {
    return (
      <div>
        <Field key={i}
          onRef={(ref) => (profileUploadRef = ref)}
          name={item.name ? item.name : null}
          component={RenderFileInputField}
          label={item.label}
          type='profile'
          id={'photo'}
          acceptType={'image/*'}
          url={apiCalls.LocationImagePath}
          getMulipleFileName={(file) => getMulipleFileName(file, item)}
          multiple={true}
        />
        <Scrollbar>
          <div className='mindivForMultipleupload' >
            {state[item.name] && state[item.name].length > 0 ? state[item.name].map((imagLocationItem, imagLocationIndex) => (<div className='col-md-2' style={{ padding: '20px' }}>
              <div key={imagLocationIndex} style={{ justifyContent: 'center' }}>
                <img
                  key={imagLocationIndex}
                  src={`${config.imgUrl}${item.imagePath}/${imagLocationItem['file']}`}
                  className='detailsImgStyle' />
                <FontAwesomeIcon icon='times-circle' className='timesCircleIcon'
                  onClick={() => removeMultipleUploadedImages(imagLocationIndex, item)}
                />
              </div >
            </div >)) : null
            }
          </div >
        </Scrollbar >
      </div >
    )
  }

  let getDeleteRowModal = () => {
    return (
      <DeleteRowModal
        openDeleteModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        selectActions={selectActions}
        deleteSelectedRow={handleActions}
        confirmModalText={confirmModalText}
      />
    )
  }

  // Store selected Images in state
  let getMulipleFileName = async (file, item) => {
    let multipleLocationImage = state[item.name];
    multipleLocationImage.push({ "file": file })
    await setState({
      [item.name]: multipleLocationImage
    });
  }

  // Remove selected image from state
  let removeMultipleUploadedImages = async (imagLocationIndex, item) => {
    let multipleLocationImage = state[item.name];
    multipleLocationImage.splice(imagLocationIndex, 1);
    await setState({
      [item.name]: multipleLocationImage
    })
  }

  let getItemField = (item, i) => {
    let { t } = props;
    return (
      <div id={item.name} key={props.type + i}
        className={(item.isAddFormHidden && formType === 'add') ? 'd-none' :
          item.name == "levels" && props.type == "Roles" && !showorHideLevelsField ? 'd-none' :
            props.type == "Leaves" && item.name == "halfDayType" && (!showLeaveHalfDayField) ? 'd-none' :
              props.type == "Leaves" && item.name == "toDate" && (!showLeaveToDateField) ? 'd-none' :
                (item.isEditFormHidden && formType === 'edit') ? 'd-none' : (item.type === 'ckeditor' || item.type === 'permission' || item.type === 'multipleprofile' ? 'col-sm-12' : (
                  (item.type === 'autoComplete' && item.name === 'assignedTo') ||
                    item.name === 'closebutton' ? 'col-sm-4' : props.sideFormLeftOrRight == "right" && props.type === 'Leaves' ? "col-sm-12" : "col-sm-6"))}
      >
        <div className="form__form-group mb-3 ml-1" >
          {item.type === 'dropDown' && item.isMultiSelect ?
            getDropdownMultiselect(i, item)
            : item.type === 'dropDown' ?
              getDropdown(i, item)
              : item.name === 'closebutton' ?
                getCloseButton(i, item)
                : item.type === 'profile' ?
                  getProfile(i, item)
                  : item.type === 'date' ?
                    getDate(i, item)
                    : item.type === 'time' ?
                      getTime(i, item)
                      : item.type === 'ckeditor' ?
                        getCkEditor(i, item)
                        : item.type === 'empty' ?
                          <div> </div>
                          : item.type === 'radio' ?
                            getRadio(i, item)
                            : item.type === 'permission' ?
                              // getRolePermissions()
                              getScreenPermissions()
                              : item.type === 'autoComplete' ?
                                getAutoComplete(i, item)
                                : item.type === 'relateAutoComplete' ?
                                  getAutoComplete(i, item)
                                  : item.type === 'textarea' ?
                                    getTextArea(i, item)
                                    : item.type === 'buttonGroup' ?
                                      item.options && item.options.length > 0 ? getButtonGroup(i, item) : null
                                      : item.type === 'multipleprofile' ?
                                        getMultiplePhotoUpload(i, item) :
                                        item.type == "password" ?
                                          getPassword(i, item)
                                          : item.type == "encryptedField" ?
                                            getPassword(i, item)
                                            /**@CheckBox */
                                            : item.type == 'checkbox' ?
                                              getCheckbox(i, item)
                                              : getDefault(i, item)

          }
        </div>
      </div>

    )
  }

  let getFields = () => {
    let allFields = <div></div>
    let item;
    for (let i = 0; i < formFields.length; i++) {
      item = formFields[i];
      if (item.show === false || (item.isAddFormHidden === true && formType === 'add') || (item.isEditFormHidden === true && formType === 'edit')) {

      } else {
        allFields = <>{allFields}{getItemField(item, i)}</>
      }
    }
    return allFields;
  }

  let getAdditionalField = () => {
    if (props.formType === "edit" && (props.type === 'Tasks' || props.type === 'Projects')) {
      return (
        <div>
          <Button color='primary' outline className='m-0 mb-1 p-1 ml-auto'
            onClick={(e) => { e.preventDefault(); setOpenCommentModal(true) }}>
            {'Add Comments'}
          </Button>
        </div>
      );
    }
    return null;
  }

  let setSlno = async (actionType) => {
    let { totalRecords, first, rows, onPageChange, isWeb } = props;
    let sLno = slno;
    if (actionType === 'back') {
      if (sLno !== 0) {
        setSslno((slno) => slno - 1);
        handleNextAndBackActions(actionType)
      } else {
        setSslno(slno + 1);
      }
    } else if (actionType === 'next') {
      let total = '';
      total = props.allUsersData.length;
      if (sLno !== total) {
        setSslno((slno) => slno + 1);
        setColored(true)
        handleNextAndBackActions(actionType);
      } else {
        setSslno(slno);
      }
    }
  }

  let getPaginationWithIcons = () => {
    let { totalRecords, first, rows, onPageChange, isWeb } = props;
    // let { slno } = state;
    return (
      <div className='d-flex h-100'>
        <div className='pagenumber-indicator'>
          {slno === 0 ? 1 : slno + 1} / {props.allUsersData.length}&nbsp;&nbsp;
        </div>
        <div>
          <ButtonGroup className='mb-0'>
            <Button color="primary"
              outline
              disabled={slno === 0 ? true : false}
              size="sm"
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              className="p-1 ml-auto mt-1 mb-0"
              onClick={() => setSlno('back')}
            >
              <FontAwesomeIcon
                icon='chevron-left'
                className='pl-1' size='lg'
                data-toggle="tool-tip" title="List"

              />
            </Button>
            <Button color="primary"
              outline
              disabled={slno === props.allUsersData.length - 1 ? true : false}
              size="sm"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              className="p-1 ml-auto mt-1 mb-0"
              onClick={() => setSlno('next')}
            >
              <FontAwesomeIcon
                icon='chevron-right'
                className='pl-1' size='lg'
                data-toggle="tool-tip" title="List"

              />
            </Button>
          </ButtonGroup>
        </div>

      </div>
    )
  }

  //Modal for submitting forms
  let getModalBody = () => {
    return (
      <ModalBody className="modal__body mb-0 ">
        <Card className='pb-0 cardForListMargin'>
          <CardBody className='tableCardBody'>
            <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
              <Loader loader={isLoading} />
              <div className="row form" >
                {getFields()}
                {sessionExpiryModal ?
                  <SessionExpiryModal
                    SOpen={sessionExpiryModal}
                  />
                  : null
                }
                {props.getDependentFields && props.getDependentFields(props.type, props.formType)}
              </div>
              {getButtonToolbar()}
            </form>
          </CardBody>
        </Card>
      </ModalBody>
    )
  }

  const onErrors = (data) => {
  }

  //fetch call for sending comments to server which is using for add comments button
  let sendCommentsToserver = (e) => {
    e.preventDefault();

    const data = {
      eventType: "COMMENTS",
      comments: editorData,
      contextId: props.type === 'Tasks' ? { task: editRowDataID } : { project: editRowDataID },
      contextType: props.type === 'Tasks' ? "task" : "PROJECT"
    };
    fetchMethodRequest('POST', 'events', data)
      .then(response => {
        if (response && response.respCode) {
          closeEditorModal();
          showToasterMessage(response.respMessage, 'success');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      })
      .catch(err => {
        return err;
      });
  };

  let getEditorText = (e) => {
    setEditorData(e);
  }

  let closeEditorModal = () => {
    setOpenCommentModal(false)
  }

  let getEditorField = () => {
    return (
      <div className="col-sm-12">
        <div className='form__form-group mb-3 mt-2'>
          <Controller
            name={'comments'}
            control={control}
            render={({ field }) => (
              <DoCKEditorField
                field={field}
                getEditorText={getEditorText}
              />
            )}
          />
        </div>
        <div className="modal__footer mb-3">
          <Button outline onClick={closeEditorModal} >{'cancel'}
          </Button>
          <Button outline style={{ marginLeft: '10px' }} onClick={(e) => sendCommentsToserver(e)} >{'create'}
          </Button>
        </div>
      </div>
    )
  }

  //getScreenBody
  let getScreenBody = () => {
    return (
      <form onSubmit={handleSubmit(submit, onErrors)} autoComplete={'off'}>
        <Loader loader={isLoading} />
        <div className={props.sideFormLeftOrRight == 'right' ? "col-12 row" : props.sideFormLeftOrRight == 'left' ? "col-12" : props.formType === 'add' ? "row form pt-3" : "row form "}>
          {getFields()}
          {getAdditionalField()}
          {openCommentModal ? getEditorField() : null}
          {sessionExpiryModal ?
            <SessionExpiryModal
              SOpen={sessionExpiryModal}
            />
            : null
          }
          {props.getDependentFields && props.getDependentFields(props.type, props.formType)}
        </div>
        {getButtonToolbar()}
      </form>
    )
  }

  //getModalView
  let getModalView = () => {
    let { handleSubmit } = props;
    let modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });
    return (
      <Modal
        isOpen={props.openFormModal}
        className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
      >
        {getModalHeader()}
        {formType === 'view' ?
          <ModalBody className="modal__body mb-0 pt-1">
            <Card className='pb-0 cardForListMargin'>
              <CardBody className='tableCardBody'>
                {getViewBody()}
                {props.editRequired ? getEditButton() : null}
              </CardBody>
            </Card>
          </ModalBody> : getModalBody(handleSubmit)}
      </Modal>
    )
  }

  let cancelUserPwdResetModal = async () => {
    setOpenUserPasswordResetModal(false);
    setActions('')
  }

  let getUserPasswordResetModal = () => {
    return (
      <UserPasswordResetModal
        openUserPasswordResetModal={openUserPasswordResetModal}
        userId={editRowDataID}
        cancelReset={cancelUserPwdResetModal}
        entityType={props.entityType}
      />
    )
  }

  let getHeader = () => {
    let { t } = props;
    if (props.displayViewOfForm != "sideForm") {
      return (
        <div className='d-flex'>
          <div className='col-12 px-0 pb-1'>
            <span className='float-left pt-2'>
              <h4 style={{ textTransform: 'capitalize' }}><b>
                <Link to={props.routeTo.startsWith("/") ? props.routeTo : `/${props.routeTo}`} onClick={closeFormModal}>
                  {props.type}
                </Link>
                {formType !== 'add' && displayBreadCrumbValue ? ` / ${displayBreadCrumbValue} ` : null}
              </b> </h4>
            </span>
          </div>
        </div>
      )
    }
  }

  let submitActionsData = async (method, url) => {
    return fetchMethodRequest(method, url)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true);
        }
        setOpenDeleteModal(false);
        setActions('');
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          await props.getDataFromServer(props.filterCriteria);
          await props.closeFormModal();
          await handleNextAndBackActions();
          // props.closeFormModal('save');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }

  let handleActions = async () => {
    let apiUrl = props.apiUrl, url = '', method = '';
    if (confirmType === 'Delete') {
      method = 'DELETE';
      url = `${apiUrl}/${editRowDataID}`;
      submitActionsData(method, url)
    }
    if (confirmType === 'Block') {
      method = 'PUT';
      url = `${apiUrl}/block/${editRowDataID}?block=true`;
      submitActionsData(method, url)
    }
    if (confirmType === 'ResetPassword') {
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
    }

  }

  // conformation for delete item
  let deleteConfirmAction = async (rowData, selectActions) => {
    setOpenDeleteModal(true);
    setSelectActions(selectActions);
  }

  let confirmActionType = async (type) => {
    if (type === 'Delete') {
      await setConfirmType(type);
      await setConfirmModalText('Are you sure want to Delete')
      deleteConfirmAction()
    } else if (type === 'Block') {
      await setConfirmType(type);
      await setConfirmModalText('Are you sure want to Block')

      deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      await setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Reset Password',
        openUserPasswordResetModal: true,
        openDeleteModal: false
      })
      await setConfirmType(type);
      await setConfirmModalText('Are you sure want to Reset Password');
      await setOpenUserPasswordResetModal(true);
      await setOpenDeleteModal(false);

      // deleteConfirmAction()
    }
  }

  //onActionsChange
  let onActionsChange = async (event, type) => {
    if (type == 'dropdownFilter') {
      await setState({
        [event.target.name]: event.target.value,
      })
      confirmActionType(event.target.value)
    }
  }

  let closeWorkedHoursModal = () => {
    setOpenWorkedHoursModal(false)
  }

  //Displaying assigned projects to the respective employee in the employee view modal
  let getProjects = () => {
    let urlHeading = window.location.href;
    let userID = decodeURIComponent(urlHeading.split('/').pop());
    let userIDWithoutQuotes = userID.replace(/^"(.*)"$/, '$1');
    userIDWithoutQuotes = userIDWithoutQuotes.replace(/"/g, '');

    if (urlHeading && urlHeading.includes('view_employees')) {
      let url = `employees/employeesProjects/${userIDWithoutQuotes}`;
      fetchMethodRequest('GET', url)
        .then(async (response) => {
          if (response && response.projectNames) {
            setProjectsList(response.projectNames)
          }
        }).catch((err) => {
          return err;
        });
    }
  }

  let getViewBody = () => {
    let { t } = props;
    return (<div>

      <div className='row'>
        <div className='col-sm-4 pb-2'>
          {/* {props.actionsTypes && props.actionsTypes.length > 0 ? <Dropdown
            style={{ minWidth: '10%', lineHeight: 1.3, }}
            className='mr-3'
            // appendTo={document.body}
            name='actions'
            value={actions}
            options={props.actionsTypes}
            placeholder={t('Actions')}
            onChange={(e) => onActionsChange(e, 'dropdownFilter')}
          /> : null} */}
        </div>
        <div className='col-sm-8 text-lg-right'>
          {originalTableFields && originalTableFields.length > 0 ? originalTableFields.map((item, index) => {
            return item.fieldType === 'Badge' && item.options && item.options.length > 0 ? item.options.map((optionsItem, optionsIndex) => {
              return (
                <Button key={optionsIndex} style={{ margin: '2px' }} color='primary' onClick={() => saveDataToServer({ "status": optionsItem.value })} disabled={userStatus === optionsItem.value ? true : false}>{optionsItem.value}</Button>
              )
            }) : null
          }) : null}
        </div>
      </div>
      {rowData && rowData.length > 0 ?
        <div className="row form" >
          {rowData.map((item, i) => {
            return (
              item.value !== "" && item.value !== null && item.label !== 'SNo' ?
                <div className={displayViewOfForm == "sideForm" && props.type == 'Leaves' ? 'col-12' : 'col-sm-6 '} key={i}>
                  <div className="row" style={{ margin: "auto" }}>
                    <div className="col-5 paddingRowDataCol">
                      <span className='fontWeight' style={{ fontWeight: "bold" }}>
                        {item.label}
                      </span>
                    </div>

                    <div className="col-7 paddingOfRowData">
                      <span>{
                        //displaying seperate modal when click on worked hours
                        item.label === "Worked Hours" ? (
                          <span style={{ color: 'blue' }} className='col-sm-auto' onClick={() => { setOpenWorkedHoursModal(true); setVisible(true) }}>
                            {item.value}
                            <EyeIcon style={{ width: '15px', marginBottom: '2px', marginLeft: '2px' }} />
                          </span>
                        ) : item.fieldType && item.fieldType == 'dropDown' ?
                          getBadgeData(item, item.value) : item.value
                      }</span>
                    </div>
                  </div>
                </div> : null
            );
          })}
          {props.type === 'Employees' && projectsList && projectsList.length ? (
            <div className="col-sm-6">
              <div className="row">
                <div className="col-5 paddingRowDataCol">
                  <span className='fontWeight' style={{ fontWeight: "bold", marginLeft: "11px" }}>
                    {'Assigned Projects'}
                  </span>
                </div>
                <div className="col-7 paddingOfRowData">
                  {projectsList.map((project, index) => (
                    <div style={{ whiteSpace: "nowrap" }} key={index}>
                      {project.toUpperCase()}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        : null
      }
      {openWorkedHoursModal && <WorkedHoursModal
        openWorkedHoursModal={openWorkedHoursModal}
        closeWorkedHoursModal={closeWorkedHoursModal}
        editRowDataID={editRowDataID}
        visible={visible}
        setVisible={setVisible}
      />}
    </div>

    )
  }

  let getBadgeData = (element, value) => {
    let mcolor = props.getColorFromOptions(element.options, value);
    return (<Badge color={mcolor} pill >{value}</Badge>)
  }

  //sorting fields
  let sortChange = (event) => {
    setSelectedRows('')
    let sortCount = sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount == 0 ? sortCount + 1 : 0;
      let sortField = event['sortField'];
      let filterCriteria = filterCriteria;
      filterCriteria['direction'] = sortCount == 0 ? "desc" : 'asc';
      filterCriteria['sortfield'] = sortField;
      setSortCount(sortCount);
      setFilterCriteria(filterCriteria);
      getActivities();
    }
  }

  let getColumns = (e, d) => {
    let { t } = props;
    let tablefieldsToShow = getTableFields();
    if (tablefieldsToShow && tablefieldsToShow.length > 0) {
      return tablefieldsToShow.map((item, i) => {
        let column = (item.show &&
          <Column key={item.field + i}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            header={t(item.header)}
            body={self.changeFieldValues}
            headerStyle={{
              padding: 4, fontWeight: 500, width: item.width, fontSize: 13,
              color: config.whiteColor, backgroundColor: config.templateColor
            }}
            filter={false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : 'search'}

            selectionMode={item.selectionMode}
          />
        )
        return column;
      })
    }
  }

  let getDataTable = () => {
    let dt;

    return (
      <DataTable
        ref={(el) => dt = el}
        value={activitiesData}
        totalRecords={totalRecordsLength}
        paginator={false}
        lazy={true}
        resizableColumns={true}
        columnResizeMode="expand"
        onSort={sortChange}
        globalFilter={props.globalFilter}
        onFilter={props.onFilterChange}
        scrollable={true}
        selection={false}
        scrollHeight='1000px'
        // style={{ marginTop: 0 }}
        emptyMessage={configMessages.noRecords}
        sortMode="single"
        // sortField="fname"
        // sortOrder={-1}
        // selectionMode={'multiple'}
        metaKeySelection={false}
        loading={isLoading}
        style={activitiesData && activitiesData.length == 0 ?
          { textAlign: 'center' }
          : null}
      >
        {getColumns()}
      </DataTable>
    )
  }

  let getActivitiesHeader = () => {
    let { t } = props
    return (
      <div className='col-12  pb-1'>
        <span className='float-left pt-2'>
          <h4 style={{ textTransform: 'capitalize' }}><b>

            <Link to={props.routeTo.startsWith("/") ? props.routeTo : `/activities`} onClick={closeFormModal}>
              {t('Activities')}
            </Link>
            {formType !== 'add' && displayBreadCrumbValue ? ` / ${displayBreadCrumbValue} ` : null}
          </b> </h4>
        </span>
        <span className='float-right pt-2'>
          {/* {getPaginator()} */}
        </span>
      </div>
    )
  }

  let toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  //getScreenView
  let getScreenView = () => {
    let { handleSubmit, allUsersData } = props;
    let selectedData = allUsersData.filter(item => item.Sno === selectedDataSno)
    let editValidation = (formType === 'view' && props.editRequired && (selectedData && selectedData[0] && selectedData[0].status !== 'Approved'));
    return (
      <div>

        {
          props.openFormModal ? <div>
            <div className='d-flex justify-content-between pb-2 mx-1'>
              <div >
                {getHeader()}
              </div>
              <div >
                {formType !== 'add' && getPaginationWithIcons()}
              </div>
            </div>

            {formType !== 'view' ? getScreenBody(handleSubmit) : getViewBody()}
            {editValidation ? getEditButton() : null}
            {formType !== 'add' && formType !== 'edit' ?
              < div >
                <div className='row'>
                  {getActivitiesHeader()}
                </div>
                <div className='row'>
                  <div className='col-sm-12'>
                    {props.type === 'Tasks' || props.type === 'Projects' ?
                      //displaying tabs modal view for tasks and projects screens
                      <HistoryView
                        historyData={activitiesData}
                        type={props.type}
                      />
                      : getDataTable()}
                    {config.paginationPosition == 'bottom' ? getPaginator() : null}
                  </div>
                </div>
              </div> : null
            }
          </div> : null
        }
      </div>
    );
  }




  let { displayViewOfForm } = props;
  return (
    <div>
      {displayViewOfForm === 'modal' ?
        getModalView() : displayViewOfForm === 'screen' || displayViewOfForm == 'sideForm' ?
          getScreenView() : null
      }
      {openDeleteModal ? getDeleteRowModal() : null}
      {openUserPasswordResetModal ? getUserPasswordResetModal() : null}
    </div>
  );

}

export default withTranslation('common')(FormModal);