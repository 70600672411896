import pluralize from "pluralize";

const camelizedString = (str) => {
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
}

const plurarizeStr = str => {
    return pluralize.plural(str);
}

const jsUcfirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const formatPropType = str => {
    return jsUcfirst(plurarizeStr(camelizedString(str))) 
}

export { formatPropType };