import React, { PureComponent, useState } from 'react';
import {
    Card, CardBody, Col, Row, Button, CardImg, ButtonToolbar, Popover, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter, Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const SessionExpiryModal = ({ closeSModal, isOpen, SOpen }) => {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         SessionExpired: false,
    //         date: new Date(),
    //         pen: false,
    //         count: 60,
    //         SessionExpired1: false
    //     };
    // }

    const [sessionExpired,setSessionExpired] = useState(false);
    const [date,setDate] = useState(new Date());
    const [pen,setPen] = useState(false); 
    const [sessionExpired1,setSessionExpired1] = useState(false);
    const [count,setCount] = useState(60);


    // componentDidMount = async () => {

    //     let spen = await this.props.adjustForm();
    //     await this.setState({ pen: spen })
    //     this.timerID = setInterval(
    //         () => this.tick(),
    //         1000
    //     );

    // }

    // componentWillUnmount() {
    //     clearInterval(this.timerID);
    // }
    const redirectMethod = async () => {

        await this.setState({ SessionExpired: true })

    }
   const logoutYes = async () => {
        localStorage.removeItem('loginBody');
        localStorage.removeItem('loginCredentials');

        sessionStorage.clear()
        setSessionExpired1(true);
        setDate('');
        setCount(60)
        // await this.setState({ SessionExpired1: true, date: '', count: 60 })


    }
    const closeModal = async () => {
        // await this.setState({ SessionExpired1: false, date: '', count: 60, pen: false });
        setSessionExpired1(true);
        setDate('');
        setCount(60);
        setPen(false);
        closeSModal();
    }
    const logoutfunc = () => {
        localStorage.clear();
        sessionStorage.clear();
        setSessionExpired(true);
        // this.setState({ SessionExpired: true, })
    }
   
       
        return (
            <div>

                <Modal isOpen={SOpen} centered={true}>

                    {!sessionExpired ? <ModalHeader className="bold-text" style={{ backgroundColor: '#0e4768', color: 'white', textAlign: 'center' }}  >
                        {"You're being timed out due to inactivity"}
                    </ModalHeader> : null}
                    <ModalBody className='content__modal'>
                        <h3 className="py-3">{'We’re Sorry'} </h3>
                        <h3 className="py-3">{'Your session has expired please login again'}</h3>
                        {/* <h1 >{this.state.date ? this.state.date.toLocaleTimeString() : null}</h1> */}

                        <Row className="pt-2 justify-content-center">
                            <Col sm={12} className="text-center">
                                {/* <button onClick={logoutfunc} className="btn btn-primary">SIGN IN AGAIN</button> */}
                                {sessionExpired1 ? <Navigate to="/log_in" /> : null}
                                {sessionExpired ? <Button className="recipients-button large create btn-create " size="lg" type="button" onClick={logoutYes}>{'Okay'}</Button> : null}


                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Link className="btn btn-primary" to="/log_in">{'Login again'}</Link>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        )
    
}
export default SessionExpiryModal;
