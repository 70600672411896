import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Attendences from './components/Attendences';

const attendences = (props,{ t }) => (
  <Container>
    <Attendences {...props} />
  </Container>
);

attendences.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(attendences);
